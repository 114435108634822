import { useIssuesDataStore } from '@/stores/issues-data'
import { useMasterDataStore } from '@/stores/master-data'

const instances = new Map()

export function useDataReader(listName: string = 'master') {
    if (instances.has(listName)) {
        return instances.get(listName)
    }

    const store = listName.includes('issues')
        ? useIssuesDataStore()
        : useMasterDataStore()

    store.$patch({ listName })
    instances.set(listName, store)

    return store
}