/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia'
import { useMainStore } from '@/stores/main'
import { useToolsStore } from '@/stores/tools'
import { useMasterDataStore } from '@/stores/master-data'

export const useIssuesDataStore = defineStore('issuesData', {
  state: () => ({
    ...useMasterDataStore().$state
  }),

  getters: {
    masterStore() {
      return useMasterDataStore()
    },

    items() {
      return this.masterStore.items
    },

    matching() {
      return this.masterStore.matching
    },
  },

  actions: {
    urls() {
      return this.getPartialPropertyList('url')
    },

    zones() {
      return this.getPartialPropertyList('zone');
    },

    countries() {
      return this.getPartialPropertyList('country');
    },

    divisions() {
      return this.getPartialPropertyList('division');
    },

    brands() {
      return this.getPartialPropertyList('brand');
    },

    getPartialPropertyList(prop: string): string[] {
      const mainStore = useMainStore();
      if (this.lastUpdated != mainStore.data.updated) {
        this.cache = {};
        this.lastUpdated = mainStore.data.updated;
      }
      if (prop in this.cache) {
        return this.cache[prop];
      } else {
        this.cache[prop] = [];
        for (let i = 0, len = this.items.length; i < len; i++) {
          const item = this.items[i];
          const masterListIndex = this.getMasterListIndex(i, item);
          const value = useMasterDataStore().getBrandInfo(masterListIndex, prop);
          if (value != null && !this.cache[prop].includes(value)) {
            this.cache[prop].push(value);
          }
        }
        return this.cache[prop].sort();
      }
    },

    getMasterListIndex(index: number, item: any[]): number {
      return useMasterDataStore().getValue(item, 'master')
    },

    getItemInfoByUrl(url: string) {
      const masterData = useMasterDataStore();
      let item;
      const urlIndex = this.urls().indexOf(url);
      const urlPropIndex = this.matching.indexOf('url');
      const masterListIndex = this.items.findIndex((item: any) => item[urlPropIndex] === urlIndex);
      if (masterListIndex !== -1) {
        item = this.items.find((entry: any) => masterData.getValue(entry, "master") === masterListIndex);
      }
      if (item === undefined) {
        throw new Error(`Fatal Error: can't find item with url '${url}'`);
      }
      return masterData.getItemInfo(masterListIndex, item);
    },

    getSortedIndices() {
      return this.items.map((item: any, i: number) => [i, this.getMasterListIndex(i, item)]);
    },

    *filteredItemList(context = 'master', filters: Record<string, any> = {}) {
      const toolsStore = useToolsStore();
      const masterData = useMasterDataStore();

      if (Object.keys(filters).length === 0) {
        filters = toolsStore.getFilters(context);
      }

      const props = ['zone', 'country', 'division', 'brand', 'framework'];
      const sortedIndices = this.getSortedIndices();
      itemsLoop: for (let i = 0, len = sortedIndices.length; i < len; i++) {
        const itemIndex = sortedIndices[i][0];
        const masterListIndex = sortedIndices[i][1];
        const item = this.items[itemIndex];
        for (let p = 0; p < props.length; p++) {
          const brandInfo = masterData.getBrandInfo(masterListIndex, props[p]);
          if (filters[props[p]].length > 0 && !filters[props[p]].includes(brandInfo)) {
            continue itemsLoop;
          }
        }
        yield masterData.getItemInfo(masterListIndex, item);
      }
    },

    *cartItemList(context = 'master') {
      const cart = useToolsStore().getCart(context)
      const masterData = useMasterDataStore();

      const sortedIndices = this.getSortedIndices();
      for (let i = 0, len = sortedIndices.length; i < len; i++) {
        const itemIndex = sortedIndices[i][0];
        const masterListIndex = sortedIndices[i][1];
        const item = this.items[itemIndex];
        const url = masterData.getBrandInfo(masterListIndex, 'url');
        if (cart.includes(url)) {
          yield masterData.getItemInfo(masterListIndex, item);
        }
      }
    },

    *selectionItemList(context = 'master', filters: Record<string, any> = {}) {
      const masterData = useMasterDataStore();
      const toolsStore = useToolsStore();

      const props = ['zone', 'country', 'division', 'brand', 'framework'];
      const cart = toolsStore.getCart(context);
      const sortedIndices = this.getSortedIndices();
      itemsLoop: for (let i = 0, len = sortedIndices.length; i < len; i++) {
        const itemIndex = sortedIndices[i][0];
        const masterListIndex = sortedIndices[i][1];
        const item = this.items[itemIndex];
        const url = masterData.getBrandInfo(masterListIndex, 'url');
        if (cart.includes(url)) {
          for (let p = 0; p < props.length; p++) {
            const brandInfo = masterData.getBrandInfo(masterListIndex, props[p]);
            if (filters[props[p]].length > 0 && !filters[props[p]].includes(brandInfo)) {
              continue itemsLoop;
            }
          }
          yield masterData.getItemInfo(masterListIndex, item);
        }
      }
    },
  }
})