import { h } from 'vue'
import BaseHeader from '@/components/templates/BaseHeader.vue'
import BaseField from '@/components/templates/BaseField.vue'
import { templateRegistry } from '@/components/templates/registry'
import type { TemplateParams } from '@/types/shared.types'
import { toString } from '@/components/templates/template-utils'

// Analytics4 Headers
function renderAnalytics4IssuesHeaders() {
  return [
    () => h(BaseHeader, { column: 'account', label: 'Account' }),
    () => h(BaseHeader, { column: 'property', label: 'Property' })
  ]
}

// TagManager Headers
function renderTagManagerIssuesHeaders() {
  return [
    () => h(BaseHeader, { column: 'account', label: 'Account' }),
    () => h(BaseHeader, { column: 'container', label: 'Container' })
  ]
}

// CampaignManager Headers
function renderCampaignManagerIssuesHeaders() {
  return [
    () => h(BaseHeader, { column: 'subaccount', label: 'Subaccount' }),
    () => h(BaseHeader, { column: 'advertiser', label: 'Advertiser' })
  ]
}

// Analytics4 Fields
function renderAnalytics4IssuesFields(params: TemplateParams) {
  const { item } = params
  let order = 100

  return [
    () => h(BaseField, {
      class: [`order${order++}`, 'issuesInfo'],
      label: 'Account:',
      value: toString(item.accountId)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, 'issuesInfo'],
      label: 'Property:',
      value: toString(item.propertyId)
    })
  ]
}

// TagManager Fields
function renderTagManagerIssuesFields(params: TemplateParams) {
  const { item } = params
  let order = 100

  return [
    () => h(BaseField, {
      class: [`order${order++}`, 'issuesInfo'],
      label: 'Account:',
      value: toString(item.accountId)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, 'issuesInfo'],
      label: 'Container:',
      value: toString(item.containerPublicId)
    })
  ]
}

// CampaignManager Fields
function renderCampaignManagerIssuesFields(params: TemplateParams) {
  const { item } = params
  let order = 100

  return [
    () => h(BaseField, {
      class: [`order${order++}`, 'issuesInfo'],
      label: 'Subaccount:',
      value: toString(item.subaccountId)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, 'issuesInfo'],
      label: 'Advertiser:',
      value: toString(item.advertiserId)
    })
  ]
}

export function registerToolIssuesTemplates() {
  // Headers registration
  templateRegistry.register('headers', 'issues-analytics4', renderAnalytics4IssuesHeaders)
  templateRegistry.register('headers', 'issues-tagmanager', renderTagManagerIssuesHeaders)
  templateRegistry.register('headers', 'issues-campaignmanager', renderCampaignManagerIssuesHeaders)

  // Fields registration
  templateRegistry.register('fields', 'issues-analytics4',
    (params: TemplateParams) => renderAnalytics4IssuesFields(params))
  templateRegistry.register('fields', 'issues-tagmanager',
    (params: TemplateParams) => renderTagManagerIssuesFields(params))
  templateRegistry.register('fields', 'issues-campaignmanager',
    (params: TemplateParams) => renderCampaignManagerIssuesFields(params))
}