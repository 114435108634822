import { h } from 'vue'
import BaseHeader from '@/components/templates/BaseHeader.vue'
import BaseField from '@/components/templates/BaseField.vue'
import { templateRegistry } from '@/components/templates/registry'
import type { TemplateParams } from '@/types/shared.types'
import { getScoreClass, toScore, toString } from '@/components/templates/template-utils'
import { splitAndCapitalize, capitalize, gotKey } from '@/utils'

// Analytics4 Headers
function renderAnalytics4StructureHeaders() {
    return [
        () => h(BaseHeader, { column: 'score', label: 'Score' }),
        () => h(BaseHeader, { column: 'accountName', label: 'Account' }),
        () => h(BaseHeader, { column: 'accountId', label: 'Account ID' }),
        () => h(BaseHeader, { column: 'propertyName', label: 'Property' }),
        () => h(BaseHeader, { column: 'propertyId', label: 'Property ID' }),
        () => h(BaseHeader, { column: 'subpropertyNb', label: 'Nb Subproperty' }),
        () => h(BaseHeader, { column: 'subpropertyName', label: 'Subproperty' }),
        () => h(BaseHeader, { column: 'subpropertyId', label: 'Subproperty ID' }),
        () => h(BaseHeader, { column: 'datastream', label: 'Datastreams' }),
        () => h(BaseHeader, { column: 'serviceLevel', label: 'Service Level' }),
    ]
}

function renderTagManagerStructureHeaders() {
    return [
        () => h(BaseHeader, { column: 'score', label: 'Score' }),
        () => h(BaseHeader, { column: 'account', label: 'Account' }),
        () => h(BaseHeader, { column: 'accountId', label: 'Account ID' }),
        () => h(BaseHeader, { column: 'container', label: 'Container' }),
        () => h(BaseHeader, { column: 'containerPublicId', label: 'Container Public ID' })
    ]
}

function renderCampaignManagerStructureHeaders() {
    return [
        () => h(BaseHeader, { column: 'score', label: 'Score' }),
        () => h(BaseHeader, { column: 'subaccount', label: 'Subaccount' }),
        () => h(BaseHeader, { column: 'subaccountId', label: 'Subaccount ID' }),
        () => h(BaseHeader, { column: 'advertiser', label: 'Advertiser' }),
        () => h(BaseHeader, { column: 'advertiserId', label: 'Advertiser ID' })
    ]
}

function renderNamedProperty(params: TemplateParams, prop: string, order: number) {
    const { item } = params
    const propIdAttribute = prop === "container" ? "containerPublicId" : `${prop}Id`
    const actualProp = item[prop] === undefined && item[`${prop}Name`] !== undefined ? `${prop}Name` : prop
    const fields = []

    if (gotKey(item, actualProp) && item[actualProp] === "n/a") {
        fields.push(
            () => h(BaseField, {
                class: [`order${order}`, 'null', 'score', `${prop}Name`],
                label: `${capitalize(prop)}:`,
                value: 'n/a'
            }),
            () => h(BaseField, {
                class: [`order${order + 1}`, 'null', 'score', propIdAttribute],
                label: `${capitalize(prop)} ID:`,
                value: 'n/a'
            })
        )
    } else if (!gotKey(item, actualProp) || item[actualProp] === null || !("found" in item[actualProp])) {
        fields.push(
            () => h(BaseField, {
                class: [`order${order}`, 'null', 'score', `${prop}Name`],
                label: `${capitalize(prop)}:`,
                value: toString(null)
            }),
            () => h(BaseField, {
                class: [`order${order + 1}`, 'null', 'score', propIdAttribute],
                label: `${capitalize(prop)} ID:`,
                value: toString(null)
            })
        )
    } else if (!item[actualProp].found) {
        fields.push(
            () => h(BaseField, {
                class: [`order${order}`, 'bad', 'score', `${prop}Name`],
                label: `${capitalize(prop)}:`,
                value: toString(item[actualProp].expected)
            }),
            () => h(BaseField, {
                class: [`order${order + 1}`, 'bad', 'score', propIdAttribute],
                label: `${capitalize(prop)} ID:`,
                value: toString(item[propIdAttribute])
            })
        )
    } else {
        const nameValue = item[actualProp].expected !== null &&
            item[actualProp].expected !== item[actualProp].actual
            ? toString(item[actualProp].actual)
            : toString(item[actualProp].actual)

        fields.push(
            () => h(BaseField, {
                class: [
                    `order${order}`,
                    item[actualProp].expected == null || item[actualProp].actual == item[actualProp].expected ? 'good' : 'warn',
                    'score',
                    `${prop}Name`
                ],
                label: `${capitalize(prop)}:`,
                value: nameValue,
                title: item[actualProp].expected !== null &&
                    item[actualProp].expected !== item[actualProp].actual
                    ? item[actualProp].expected
                    : undefined
            }),
            () => h(BaseField, {
                class: [`order${order + 1}`, 'good', 'score', propIdAttribute],
                label: `${capitalize(prop)} ID:`,
                value: toString(item[propIdAttribute])
            })
        )
    }

    return fields
}

function renderNamedGA4Property(params: TemplateParams, prop: string, order: number, style: string) {
    const { item } = params
    const fields = []

    if (gotKey(item, prop) && item[prop] === "n/a") {
        fields.push(() => h(BaseField, {
            class: [`order${order}`, 'null', 'score', prop],
            label: `${splitAndCapitalize(prop)}:`,
            value: 'n/a'
        }))
    } else if (gotKey(item, prop) && item[prop] === null && prop.endsWith('Id')) {
        fields.push(() => h(BaseField, {
            class: [`order${order}`, style, 'score', prop],
            label: `${splitAndCapitalize(prop)}:`,
            value: toString(null)
        }))
    } else if (gotKey(item, prop) && item[prop] === null) {
        fields.push(() => h(BaseField, {
            class: [`order${order}`, 'null', 'score', prop],
            label: `${splitAndCapitalize(prop)}:`,
            value: 'n/a'
        }))
    } else if (!gotKey(item, prop)) {
        fields.push(() => h(BaseField, {
            class: [`order${order}`, 'null', 'score', prop],
            label: `${splitAndCapitalize(prop)}:`,
            value: toString(null)
        }))
    } else {
        let value
        if (item[prop] != null && item[prop].expected !== null && item[prop].expected !== item[prop].actual) {
            value = toString(item[prop].actual)
        } else if (item[prop] != null && item[prop].actual !== null && item[prop].actual !== undefined) {
            value = toString(item[prop].actual)
        } else if (item[prop] != null && typeof item[prop] !== 'object') {
            value = toString(item[prop])
        } else if (item[prop] == null) {
            fields.push(() => h(BaseField, {
                class: [`order${order}`, 'null', 'score', prop],
                label: `${splitAndCapitalize(prop)}:`,
                value: 'n/a'
            }))
            return fields
        } else {
            value = toString(null)
        }

        fields.push(() => h(BaseField, {
            class: [`order${order}`, style, 'score', prop],
            label: `${splitAndCapitalize(prop)}:`,
            value,
            title: item[prop]?.expected !== null &&
                item[prop]?.expected !== item[prop]?.actual
                ? item[prop].expected
                : undefined
        }))
    }

    return fields
}

function renderAnalytics4StructureFields(params: TemplateParams) {
    const { item, listName, activatedFeatures } = params
    const prefix = listName?.replace("-", ".")
    const relatedFeatures = activatedFeatures?.filter(elem => elem.startsWith(prefix ?? ''))
    let order = 100
    const fields = []

    fields.push(() => h(BaseField, {
        class: [`order${order++}`, getScoreClass(item.score)],
        label: 'Score:',
        value: toScore(item.score)
    }))

    if (relatedFeatures?.includes(`${prefix}.account-id`)) {
        let style = 'good'
        if (item.accountId == null) {
            style = 'bad'
        } else if (item.accountName !== null && item.accountName.actual !== item.accountName.expected) {
            style = 'warn'
        }
        fields.push(...renderNamedGA4Property(params, 'accountName', order, style))
        fields.push(...renderNamedGA4Property(params, 'accountId', order + 1, style))
        order += 2
    }

    if (relatedFeatures?.includes(`${prefix}.property-id`)) {
        let style = 'good'
        if (item.propertyId == null) {
            style = 'bad'
        } else if (item.propertyName !== null && item.propertyName.actual !== item.propertyName.expected) {
            style = 'warn'
        }
        fields.push(...renderNamedGA4Property(params, 'propertyName', order + 2, style))
        fields.push(...renderNamedGA4Property(params, 'propertyId', order + 3, style))
        order += 2
    }

    if (relatedFeatures?.includes(`${prefix}.subproperty-id`)) {
        let style = 'good'
        if (item.subpropertyId == null) {
            style = 'bad'
        } else if (item.subpropertyName !== null && item.subpropertyName.actual !== item.subpropertyName.expected) {
            style = 'warn'
        }

        if (relatedFeatures?.includes(`${prefix}.subproperty-nb`)) {
            fields.push(() => h(BaseField, {
                class: [`order${order + 4}`, getScoreClass(Number.isInteger(item.subpropertyNb) ? `${toString(item.subpropertyNb)} / 1` : toString(item.subpropertyNb))],
                label: 'Subproperties:',
                value: Number.isInteger(item.subpropertyNb) ? `${toString(item.subpropertyNb)} / 1` : toString(item.subpropertyNb)
            }))
        }

        fields.push(...renderNamedGA4Property(params, 'subpropertyName', order + 5, style))
        fields.push(...renderNamedGA4Property(params, 'subpropertyId', order + 6, style))
        order += 3
    }

    if (relatedFeatures?.includes(`${prefix}.property-datastream-web-id`)) {
        fields.push(() => h(BaseField, {
            class: [`order${order + 7}`, getScoreClass(item.datastream)],
            label: 'Datastreams:',
            value: toString(item.datastream)
        }))
    }

    if (relatedFeatures?.includes(`${prefix}.property-serviceLevel`)) {
        let value = ""
        if (item.propertyServicelevel?.actual !== undefined) {
            value = item.propertyServicelevel.actual
        }
        fields.push(() => h(BaseField, {
            class: [`order${order + 8}`, getScoreClass(item.propertyServicelevel)],
            label: 'Service Level:',
            value: toString(value)
        }))
    }

    return fields
}

function renderTagManagerStructureFields(params: TemplateParams) {
    const { item } = params
    let order = 100
    const fields = [
        () => h(BaseField, {
            class: [`order${order++}`, getScoreClass(item.score)],
            label: 'Score:',
            value: toScore(item.score)
        }),
        ...renderNamedProperty(params, 'account', order),
        ...renderNamedProperty(params, 'container', order + 2)
    ]

    return fields
}

function renderCampaignManagerStructureFields(params: TemplateParams) {
    const { item } = params
    let order = 100
    const fields = [
        () => h(BaseField, {
            class: [`order${order++}`, getScoreClass(item.score)],
            label: 'Score:',
            value: toScore(item.score)
        }),
        ...renderNamedProperty(params, 'subaccount', order),
        ...renderNamedProperty(params, 'advertiser', order + 2)
    ]

    return fields
}

export function registerToolStructureTemplates() {
    // Headers
    templateRegistry.register('headers', 'list-analytics4-structure',
        () => renderAnalytics4StructureHeaders())

    templateRegistry.register('headers', 'list-tagmanager-structure',
        () => renderTagManagerStructureHeaders())

    templateRegistry.register('headers', 'list-campaignmanager-structure',
        () => renderCampaignManagerStructureHeaders())

    // Fields
    templateRegistry.register('fields', 'list-analytics4-structure',
        (params: TemplateParams) => renderAnalytics4StructureFields(params))

    templateRegistry.register('fields', 'list-tagmanager-structure',
        (params: TemplateParams) => renderTagManagerStructureFields(params))

    templateRegistry.register('fields', 'list-campaignmanager-structure',
        (params: TemplateParams) => renderCampaignManagerStructureFields(params))
}