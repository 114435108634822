/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore, storeToRefs } from 'pinia'
import { APAM_VERSION, APAM_DISMISSAL_MAX_AGE } from '@/config/config-front-end'
import { data as staticData } from '@/static-data/data'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { useToolsStore } from '@/stores/tools'

let toastInstance: ToastServiceMethods | null = null

interface ModalParams {
    opened: boolean,
    title: string;
    message: string;
    image: string | null;
    buttonLabel: string;
}

interface FixCartModalParams {
    isOpen: boolean,
    items: string[] | null,
    rules: string[],
    item: string | null,
    user: string | null
}

interface SelectionStatusParams {
    allSelected: boolean,
    partiallySelected: boolean,
    noSelected: boolean,
    topic: string | null
}

interface DataItem {
    items: any[];
    matching: any[];
    updated?: number;
    fetched: number;
    activatedFeatures?: any[];
}

interface Selectors {
    zone: string[]
    country: string[]
    division: string[]
    brand: string[]
    framework: string[]
}

interface AppState {
    lastDismissal: number;
    loaderVisible: boolean;
    app: {
        page: string;
        offline: boolean;
        authenticationTime: number;
        authenticationEmail: string;
        modal: ModalParams;
        fixCartModal: FixCartModalParams;
    },
    data: {
        version: number;
        updated: number;
        md5: string;
        zone: string[];
        country: string[];
        division: string[];
        brand: string[];
        framework: string[];
        url: string[];
        master: DataItem;
        tools: DataItem;
        topics: DataItem;
        fetched: number;
        [key: string]: any;
    };
    currentContext: string
    contexts: {
        [key: string]: {
            selectors: {
                zone: string[]
                country: string[]
                division: string[]
                brand: string[]
                framework: string[]
            }
            filters: {
                zone: string[]
                country: string[]
                division: string[]
                brand: string[]
                framework: string[]
            }
            cart: string[]
        }
    }
    fixCart: Record<string, any>;
    selectionStatus: SelectionStatusParams;
    actions: Record<string, any>;
    status: Record<string, any>;
    flags: {
        gotNewData: {
            [key: string]: boolean;
        };
        scoreUpdated: Record<string, any>;
        renderCart: boolean;
        updateStorage: boolean;
    };
    sorting: {
        md5: string;
        listName: string;
        column: string;
        order: string | null;
        indices: number[] | null;
    };
}
const getDefaultState = () => ({
    data: {
        ...staticData,
        fetched: staticData.updated,
        version: APAM_VERSION
    }
})

export const useMainStore = defineStore('main', {
    state: (): AppState => ({
        lastDismissal: 0,
        loaderVisible: false,
        app: {
            page: '',
            offline: false,
            authenticationTime: 0,
            authenticationEmail: '',
            modal: {
                opened: false,
                title: '',
                message: '',
                buttonLabel: '',
                image: null
            },
            fixCartModal: {
                isOpen: false,
                items: null,
                rules: [],
                item: null,
                user: null
            }
        },
        data: {
            version: APAM_VERSION,
            updated: 0,
            md5: '',
            zone: [],
            country: [],
            division: [],
            brand: [],
            framework: [],
            url: [],
            master: {
                items: [],
                matching: [],
                activatedFeatures: [],
                fetched: 0
            },
            tools: {
                items: [],
                matching: [],
                updated: 0,
                fetched: 0
            },
            topics: {
                items: [],
                matching: [],
                updated: 0,
                fetched: 0
            },
            fetched: 0
        },
        currentContext: 'master',
        contexts: {
            master: {
                selectors: {
                    zone: [],
                    country: [],
                    division: [],
                    brand: [],
                    framework: []
                },
                filters: {
                    zone: [],
                    country: [],
                    division: [],
                    brand: [],
                    framework: []
                },
                cart: [],
            },
            "issues-analytics4": {
                selectors: {
                    zone: [],
                    country: [],
                    division: [],
                    brand: [],
                    framework: []
                },
                filters: {
                    zone: [],
                    country: [],
                    division: [],
                    brand: [],
                    framework: []
                },
                cart: [],
            },
            "issues-campaignmanager": {
                selectors: {
                    zone: [],
                    country: [],
                    division: [],
                    brand: [],
                    framework: []
                },
                filters: {
                    zone: [],
                    country: [],
                    division: [],
                    brand: [],
                    framework: []
                },
                cart: [],
            },
            "issues-tagmanager": {
                selectors: {
                    zone: [],
                    country: [],
                    division: [],
                    brand: [],
                    framework: []
                },
                filters: {
                    zone: [],
                    country: [],
                    division: [],
                    brand: [],
                    framework: []
                },
                cart: [],
            }
        },
        fixCart: [],
        selectionStatus: {
            allSelected: false,
            partiallySelected: false,
            noSelected: true,
            topic: null
        },
        actions: {},
        status: {},
        flags: {
            gotNewData: {
                apam: false,
                tools: false,
                topics: false
            },
            scoreUpdated: {},
            renderCart: false,
            updateStorage: false
        },
        sorting: {
            md5: '',
            listName: '',
            column: '',
            order: '',
            indices: []
        }
    }),
    getters: {
        currentContextData: (state) => state.contexts[state.currentContext],

        getCart: (state) => (context?: string) => {
            const ctx = context || state.currentContext
            return state.contexts[ctx].cart
        },

        getFilters: (state) => (context?: string) => {
            const ctx = context || state.currentContext
            return state.contexts[ctx].filters
        },

        getSelectors: (state) => (context?: string) => {
            const ctx = context || state.currentContext
            return state.contexts[ctx].selectors
        },

        getRenderCartFlag: (state) => state.flags.renderCart,
    },
    actions: {
        setContext(context: string) {
            this.currentContext = context
        },

        updatePage(page: string) {
            this.app.page = page
            const toolsStore = useToolsStore()
            toolsStore.setPage(page)
        },

        updateOffline(offline: boolean) {
            if (offline !== this.app.offline) {
                this.showToast(
                    `You are now ${offline ? "offline" : "online"}.`,
                    offline ? 'warn' : 'info'
                )
            }
            this.app.offline = offline
        },

        setToastInstance(toast: ToastServiceMethods) {
            toastInstance = toast
        },

        showToast(message: string, severity: 'success' | 'info' | 'warn' | 'error' = 'info') {
            if (!toastInstance) {
                console.error('Toast service not initialized')
                return
            }

            toastInstance.add({
                severity,
                summary: message,
                life: 3000,
                group: 'br'
            })
        },

        openModal(title: string, message: string, buttonLabel: string, image: string | null = null) {
            this.app.modal = {
                opened: true,
                title,
                message,
                buttonLabel,
                image
            }
        },

        closeModal() {
            this.app.modal.opened = false
        },

        setAuthenticationTime(timestamp: number) {
            this.app.authenticationTime = timestamp
        },

        resetAuthenticationTime() {
            this.setAuthenticationTime(0)
        },

        setAuthenticationEmail(email: string) {
            this.app.authenticationEmail = email
        },

        async fetchData(listName: string, url: string) {
            const _fetchCurrentData = async (listName: string, url: string, isFirstTime: boolean = true) => {
                try {
                    const response = await fetch(url);
                    const json = await response.json();

                    if (Object.keys(json).length === 0) {
                        console.log("Error: got empty data");
                        this.openModal('Error', 'Failed to refresh data (got empty response).\nClick OK to continue.', 'OK');
                    } else if (!("version" in json) || json.version < APAM_VERSION) {
                        if (isFirstTime) {
                            console.log(`Got incompatible data (remote: '${json.version}' is older than local: '${APAM_VERSION}').`);
                            this.openModal('Update', 'The data that was fetched is outdated.\nTrying again…', 'OK');
                            await _fetchCurrentData(listName, url, false);
                        } else {
                            console.log(`Still got incompatible data (remote: '${json.version}' vs. local: '${APAM_VERSION}').`);
                            this.openModal('Update', "The data was fetched is still outdated.\nIt's an unexpected behavior, contact the APAM developers team to investigate it.", 'OK');
                        }
                    } else if (json.version > APAM_VERSION) {
                        const now = Date.now();
                        if ((now - this.lastDismissal) > APAM_DISMISSAL_MAX_AGE) {
                            this.lastDismissal = now;
                            if (isFirstTime) {
                                console.log(`Got incompatible data (remote: '${json.version}' is more recent than local: '${APAM_VERSION}').`);
                                this.openModal('Update', 'A new version of the app is available! Please click Refresh to update, or OK to continue.', 'OK');
                            } else {
                                console.log(`Still got incompatible application (remote: '${json.version}' vs. local: '${APAM_VERSION}').`);
                                this.openModal('Update', "The application updated is still outdated.\nIt's an unexpected behavior, contact the APAM developers team to investigate it.", 'OK');
                            }
                        }
                    } else {
                        if (!isFirstTime) {
                            this.openModal('Update', 'Successfully fetched fresh data!', 'OK');
                        }
                        this.data = json;
                        if ("tools" in json && "topics" in json) {
                            this.dataIsNew("apam");
                        } else {
                            this.dataIsNew(listName);
                        }
                    }
                } catch (err) {
                    console.log(err);
                }
            };

            await _fetchCurrentData(listName, url);
        },

        populateSelectors(selectors: Partial<Selectors>, context: string = 'master') {
            this.contexts[context].selectors = {
                ...this.contexts[context].selectors,
                ...selectors
            }
        },

        resetSelectors(context: string = 'master') {
            const data = this.data
            this.contexts[context].selectors = {
                zone: data.zone,
                country: data.country,
                division: data.division,
                brand: data.brand,
                framework: this.contexts[context].selectors.framework
            }
        },

        setFilters(filters: Partial<Selectors>, context: string = 'master') {
            this.contexts[context].filters = {
                ...this.contexts[context].filters,
                ...filters
            }
        },

        resetFilters(context: string = 'master') {
            this.contexts[context].filters = {
                zone: [],
                country: [],
                division: [],
                brand: [],
                framework: []
            }
        },

        addToCart(urls: string[], context?: string) {
            const ctx = context || this.currentContext
            this.contexts[ctx].cart = [...new Set([...this.contexts[ctx].cart, ...urls])]
        },

        removeFromCart(items: string[], context?: string) {
            const ctx = context || this.currentContext
            this.contexts[ctx].cart = this.contexts[ctx].cart.filter((item: any) => !items.includes(item));
        },

        resetCart(context?: string) {
            const ctx = context || this.currentContext
            this.contexts[ctx].cart = [];
        },

        toggleSelectAll(urls: string[]) {
            const context = this.currentContext
            const currentCart = this.contexts[context].cart

            if (urls.every(url => currentCart.includes(url))) {
                this.contexts[context].cart = []
            } else {
                this.contexts[context].cart = [...urls]
            }

            if (this.contexts[context].cart.length === 0) {
                this.flags.renderCart = false
            }
        },

        addToFixCart(items: []) {
            this.fixCart.push(...items);
        },

        removeFromFixCart(items: string[]) {
            this.fixCart = this.fixCart.filter((item: string) => !items.includes(item));
        },

        resetFixCart() {
            this.fixCart = [];
        },

        updateSelectionStatus(allSelected: boolean, partiallySelected: boolean, noSelected: boolean, topic: string | null = null) {
            this.selectionStatus = { allSelected, partiallySelected, noSelected, topic };
        },

        setStatus(page: string, message: string) {
            this.status[page] = message;
        },

        dataIsNew(listName: string) {
            this.setDataStatus(listName, true);
        },

        dataIsOld(listName: string) {
            this.setDataStatus(listName, false);
        },

        renderCart() {
            this.flags.renderCart = true;
        },

        dontRenderCart() {
            this.flags.renderCart = false;
        },

        toggleStorageFlag() {
            this.flags.updateStorage = !this.flags.updateStorage;
        },

        startAction(listName: string, name: string, urls: string[]) {
            if (!this.actions[listName]) {
                this.actions[listName] = {};
            }
            if (!this.actions[listName][name]) {
                this.actions[listName][name] = [];
            }
            this.actions[listName][name].push(...urls);
        },

        endAction(listName: string, name: string, urls: string[]) {
            if (this.actions[listName] && this.actions[listName][name]) {
                this.actions[listName][name] = this.actions[listName][name]
                    .filter((url: string) => !urls.includes(url));

                if (this.actions[listName][name].length === 0) {
                    delete this.actions[listName][name];
                }

                if (Object.keys(this.actions[listName]).length === 0) {
                    delete this.actions[listName];
                }
            }
        },

        clearActions() {
            this.actions = [];
        },

        sortBy(md5: string, listName: string, column: string, order: string | null, indices: number[] | null) {
            this.sorting = { md5, listName, column, order, indices };
        },

        setDataStatus(listName: string, isNew: boolean) {
            const toolsStore = useToolsStore();
            const { getAllListNames } = storeToRefs(toolsStore)
            if (listName === "apam") {
                this.flags.gotNewData.apam = isNew;
                for (const listName of getAllListNames.value) {
                    this.flags.gotNewData[listName] = isNew;
                }
            } else {
                this.flags.gotNewData[listName] = isNew;
            }
        },

        displayModalError(title: string = "Error", message: string = "The application has encountered an unknown error.", image: string | null = null) {
            this.openModal(title, message, 'OK', image);
        },

        displayModalException(exception: any, title: string = "Error", message: string = "The application has encountered an unknown error.") {
            let error = exception;
            if ("result" in error && typeof error.result !== 'string') {
                error = error.result;
            }
            if ("error" in error && typeof error.error !== 'string') {
                error = error.error;
            }
            if ("result" in error && typeof error.result !== 'string') {
                error = error.result;
            }

            if (typeof error == 'string') message = error;
            else if ("message" in error && typeof error.message == 'string') message = error.message;
            else if ("details" in error && typeof error.details == 'string') message = error.details;
            this.displayModalError(title, message.replace(". ", ".\n\n"));
        },

        setLoaderVisible(visible: boolean) {
            this.loaderVisible = visible;
        },

        openFixCartModal(data: {
            items: any,
            rules: string[],
            item: any,
            user: string
        }) {
            this.app.fixCartModal = {
                isOpen: true,
                ...data
            }
        },
    },
    persist: {
        key: 'state',
        storage: localStorage,
        beforeHydrate: (state) => {
            const storedVersion = localStorage.getItem('stateVersion')

            if (!storedVersion || storedVersion !== APAM_VERSION.toString()) {
                console.log('Creating initial state from scratch…')
                return {
                    ...state,
                    ...getDefaultState()
                }
            }

            if (state.store.data.version !== APAM_VERSION) {
                console.log('Data version mismatch, resetting state…')

                return {
                    ...state,
                    ...getDefaultState()
                }
            }

            return state
        },
        afterHydrate: () => {
            localStorage.setItem('stateVersion', APAM_VERSION.toString())
        }
    }
})