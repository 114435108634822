/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  APAM_DISPLAY_AUTHENT_LOGS,
  APAM_RESTRICT_USERS_TO_LOREAL,
  APAM_API_SCOPE,
  APAM_CLIENT_ID,
  APAM_DISPLAY_FIX_LOGS
} from "@/config/environment-front-end";

declare global {
  interface Window {
    apam: any,
    google: any
  }
}
import { useMainStore } from '@/stores/main';
import { cloneObject } from "@/utils";

export class GoogleClient {
  private mainStore = useMainStore();
  scope: string[];
  scopeValidate: string[] | undefined;

  constructor() {
    this.scope = [APAM_API_SCOPE];
    this.scopeValidate = undefined;
  }

  checkScope() {
    this.initVarApamGlobalIfUndefined();
    let isAllScopeValidate = true;

    const scopeCopy = cloneObject(this.scope);
    const s = scopeCopy[0].split(' ');
    scopeCopy.shift();
    const scope = s.concat(scopeCopy);

    if (this.scopeValidate === undefined) {
      isAllScopeValidate = false;
    } else {
      scope.forEach((elem: string) => {
        if (this.scopeValidate && !this.scopeValidate.includes(elem)) {
          isAllScopeValidate = false;
        }
      })
    }
    return isAllScopeValidate
  }

  initVarApamGlobalIfUndefined() {
    if (window.apam === undefined) {
      const retrievedObject = localStorage.getItem('gsi_token')
      const apamData = retrievedObject ? JSON.parse(retrievedObject) : null;
      if (apamData !== null && new Date().toISOString() < apamData.expires_in) {
        window.apam = apamData;
        this.scopeValidate = apamData.scope;
      } else {
        window.apam = {
          access_token: undefined,
          expires_in: undefined,
          gsiClient: undefined,
          scope: undefined
        }
      }
    }
  }

  async loadAndExecuteGSI(eventName = "googleGsiInitialized"): Promise<void> {
    this.initVarApamGlobalIfUndefined();
    return new Promise(() => {
      if (window.google === undefined) {
        const elt = document.getElementById('app')

        if (APAM_DISPLAY_AUTHENT_LOGS) {
          console.log(`[AUTENT STEPS]GSI Loading asked`)
        }
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.addEventListener('load', () => {
          if (APAM_DISPLAY_AUTHENT_LOGS) {
            console.log(`[AUTENT STEPS]GSI Loaded, let's init it`)
          }
          if (window.apam.gsiClient === undefined) {
            this.gsiInit(eventName);
          }
        });
        if (elt) {
          elt.appendChild(script);
        } else {
          console.error('Element with id "app" not found.');
        }
      }
    })
  }

  signInFixButton(eventName: string | undefined, override = false) {
    this.initVarApamGlobalIfUndefined();
    if (window.google !== undefined && window.apam.gsiClient !== undefined) {
      return this.gsiClientRequestToken(eventName, override);
    } else {
      if (window.google === undefined) {
        return this.loadAndExecuteGSI(eventName)
      } else {
        return this.gsiInit(eventName)
      }
    }
  }

  signIn() {
    this.initVarApamGlobalIfUndefined();
    if (window.google != undefined && window.apam.gsiClient != undefined) {
      return this.gsiClientRequestToken();
    } else {
      if (window.google === undefined) {
        return this.loadAndExecuteGSI()
      } else {
        console.log(window);
        return this.gsiInit()
      }
    }
  }

  async gsiInit(eventName = "googleGsiInitialized") {
    return new Promise(() => {
      if (APAM_DISPLAY_AUTHENT_LOGS) {
        console.log('[AUTENT STEPS] google.accounts.oauth2.initTokenClient')
      }
      this.initVarApamGlobalIfUndefined();
      if (window.apam.gsiClient === undefined) {
        const scope = this.scope.join(' ');
        window.apam.gsiClient = window.google.accounts.oauth2.initTokenClient({
          client_id: APAM_CLIENT_ID,
          scope: scope,
          prompt: '',
          hosted_domain: 'loreal.com',
          callback: '',  // defined at request time
          error_callback: (err: any) => {
            console.log(err)
            return this.gsiInitErrorCallback(err)
          },
        });

        if (APAM_DISPLAY_AUTHENT_LOGS) {
          console.log(`[AUTENT STEPS]GSI initialized`)
        }
      }
      const event = new CustomEvent(eventName, {
        detail: {
          lib: 'gsi',
          client: window.apam.gsiClient
        }
      });
      document.dispatchEvent(event);
      return window.apam.gsiClient
    })
  }

  async gsiClientRequestToken(eventName = 'google_gsi_connected', override = false) {
    this.initVarApamGlobalIfUndefined();
    window.apam.gsiClient.callback = (tokenResponse: any) => {
      return this.gsiInitCallBack(tokenResponse, eventName);
    }

    return new Promise(() => {
      if (APAM_DISPLAY_AUTHENT_LOGS) {
        console.log('[AUTENT STEPS] window.apam.gsiClient.requestAccessToken()')
      }
      if (override) {
        const scope = this.scope.join(' ');
        return window.apam.gsiClient.requestAccessToken({ scope });
      } else {
        return window.apam.gsiClient.requestAccessToken();
      }
    })
  }

  callEvent(eventName: string, details = undefined) {
    let event = undefined;
    if (details == undefined) {
      event = new Event(eventName);
    } else {
      event = new CustomEvent(eventName, details);
    }
    if (APAM_DISPLAY_AUTHENT_LOGS || APAM_DISPLAY_FIX_LOGS) {
      console.log(`[GoogleClient]Fire ${eventName}... `)
    }
    document.dispatchEvent(event);
  }

  async gsiInitCallBack(tokenResponse: { scope: string | undefined; access_token: undefined; hd: string | undefined; expires_in: number; }, eventName = 'google_gsi_connected') {
    let jsonResponse = undefined;
    if (tokenResponse.scope !== undefined) {
      this.scopeValidate = tokenResponse.scope.split(' ')
    }

    if (tokenResponse.access_token !== undefined) {
      const response = await fetch(`https://www.googleapis.com/oauth2/v2/userinfo?access_token=${tokenResponse.access_token}`);
      jsonResponse = await response.json();
      if (jsonResponse.email.includes('@loreal.com')) {
        if (tokenResponse.hd === undefined) {
          console.log(jsonResponse);
          tokenResponse.hd = 'loreal.com';
        }
      }
    }

    if (APAM_RESTRICT_USERS_TO_LOREAL && tokenResponse.hd !== undefined && tokenResponse.hd === 'loreal.com') {
      if (tokenResponse.access_token !== undefined) {
        if (this.checkScope()) {
          const expirationDate = new Date(new Date().getTime() + tokenResponse.expires_in * 1000).toISOString();
          window.apam.access_token = tokenResponse.access_token;
          window.apam.expires_in = expirationDate;
          window.apam.scope = this.scopeValidate;
          localStorage.setItem('gsi_token', JSON.stringify({ access_token: tokenResponse.access_token, expires_in: expirationDate, gsiClient: undefined, scope: this.scopeValidate }))
        }

        this.mainStore.setAuthenticationEmail(jsonResponse.email);
        this.mainStore.setAuthenticationTime(Date.now());

        const event = new CustomEvent(eventName, {
          detail: {
            token: tokenResponse.access_token
          }
        });
        document.dispatchEvent(event);
      }
    } else {
      const eventFailed = new Event('GoogleClientInitializedFailed');
      document.dispatchEvent(eventFailed);
      this.mainStore.openModal('Information', "You've been signed out because your email is not allowed or because you haven't checked all the boxes in Google's identification popup in order to allow APAM to do the fix.", 'images/guide.jpg')
    }
  }

  gsiInitErrorCallback(err: { message: any; type: any; }) {
    if (APAM_DISPLAY_AUTHENT_LOGS) {
      console.log('[AUTENT STEPS] google.accounts.oauth2.initTokenClient error_callback', err)
    }
    let msg = err.message;
    switch (err.type) {
      case "popup_failed_to_open":
        msg = "Your browser is blocking popups, which prevents Google's identification screen from showing.\n\nPlease enable popups and try again."
        break;
      case "popup_closed":
        msg = "You tried to connect with an account out of @loreal.com or you've closed Google's identification screen.\n\nPlease enable popups and try again."
        this.removeGstateCookieIfExist();
        break;

      default:
        console.log('requestTokenAccess() ', err)
        break;
    }
    this.mainStore.openModal('Error', msg, 'OK')
    const event = new CustomEvent('GoogleClientInitializedFailed', {
      detail: {
        error: err,
        message: msg
      }
    });
    document.dispatchEvent(event);
  }

  signOut() {
    this.initVarApamGlobalIfUndefined();
    this.mainStore.setAuthenticationTime(0);
  }

  removeGstateCookieIfExist() {
    if (document.cookie.indexOf("g_state") !== -1) {
      const days = -1;
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = "; expires=" + date.toUTCString();
      const value = "";
      document.cookie = "g_state=" + value + expires + "; path=/";
    }
  }
}