import { registerToolDataPrivacyTemplates } from './tool-dataprivacy'
import { registerToolIssuesTemplates } from './tool-issues'
import { registerToolOverviewTemplates } from './tool-overview'
import { registerToolStructureTemplates } from './tool-structure'
import { registerToolUncategorizedTemplates } from './tool-uncategorized'
import { registerTopicOverviewTemplates } from './topic-overview'

export function registerAllTemplates() {
    registerToolOverviewTemplates()
    registerToolStructureTemplates()
    registerToolDataPrivacyTemplates()
    registerToolIssuesTemplates()
    registerTopicOverviewTemplates()
    registerToolIssuesTemplates()
    registerToolUncategorizedTemplates()
}