/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMainStore } from './stores/main'

/** Object functions **************************************************************************************************/

export const cloneArg = function (arg: any): any {
    if (typeof arg === "object" && arg !== null) {
        const copy = new arg.constructor();
        for (const key in arg) {
            copy[key] = cloneArg(arg[key]);
        }
        return copy;
    } else {
        return arg;
    }
};

export const cloneObject = function (obj: any): any {
    const copy = new obj.constructor();
    for (const key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
            copy[key] = cloneObject(obj[key]);
        } else {
            copy[key] = obj[key];
        }
    }
    return copy;
};

export const getKeyByValue = function (object: any, value: any): string | undefined {
    return Object.keys(object).find(key => object[key] === value);
};

export const map = function (arg: any, callback: any): any {
    if (Array.isArray(arg)) {
        return arg.map(callback);
    } else if (typeof arg === "object" && arg !== null) {
        const result: any = {};
        for (const key in arg) {
            result[key] = callback(arg[key]);
        }
        return result;
    } else {
        throw new Error(`Error: invalid type "${typeof arg}" for map() (must be an object or an array)`);
    }
};

export const isObject = function (arg: any): boolean {
    return typeof arg === "object" && arg !== null && !Array.isArray(arg);
};

export const isObjectOrArray = function (arg: any): boolean {
    return typeof arg === "object" && arg !== null;
};

export const gotKey = function (arg: any, key: string): boolean {
    return typeof arg === "object" && arg !== null && key in arg;
};

export const getValueOrNull = function (arg: any, key: string): any {
    return typeof arg === "object" && arg !== null && key in arg ? arg[key] : null;
};

export const filterAttributes = function (obj: any, include: string[] = [], exclude: string[] = []): any {
    let attributes = Object.keys(obj);
    attributes = attributes.filter(attribute => include.includes(attribute));
    attributes = attributes.filter(attribute => !exclude.includes(attribute));
    return attributes.reduce((newObj: { [key: string]: any }, key) => {
        newObj[key] = obj[key];
        return newObj;
    }, {});
};

/** Array functions ***************************************************************************************************/

export const isNonEmptyArray = function (someArray: any[] | null): boolean {
    return Array.isArray(someArray) && someArray.length > 0;
};

export const getLastIndexOf = function (someArray: any[]): number | undefined {
    return (Array.isArray(someArray) && someArray.length > 0) ? (someArray.length - 1) : undefined;
};

export const getLastElementOf = function (someArray: any[]): any {
    return (Array.isArray(someArray) && someArray.length > 0) ? someArray[someArray.length - 1] : undefined;
};

export const unique = function (arg: any[]): any[] {
    const res: any[] = [];
    for (let i = 0, imax = arg.length; i < imax; i++) {
        if (!res.includes(arg[i])) {
            res.push(arg[i]);
        }
    }
    return res;
};

export const sortByValue = function (a: any, b: any): number {
    if (a < b) return -1;
    else if (a > b) return 1;
    else return 0;
};

export const multisort = function (a: any, b: any, keys: string[]): number {
    for (let i = 0, imax = keys.length; i < imax; i++) {
        const key = keys[i];
        if (a[key] === undefined || b[key] === undefined) {
            continue;
        } else if (a[key] < b[key]) {
            return -1;
        } else if (a[key] > b[key]) {
            return 1;
        } else {
            continue;
        }
    }
    return 0;
};

/** String functions **************************************************************************************************/

export const toString = function (value: any): string {
    if (value === undefined || value === null) {
        return "";
    } else {
        return String(value);
    }
};

export const roundToDecimals = function (number: number, maxDecimals: number = 0): string {
    return number.toLocaleString(undefined, { maximumFractionDigits: maxDecimals });
};

export const capitalize = function (str: string): string {
    return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

export const splitAndCapitalize = function (str: string): string {
    return str[0].toUpperCase() + str.slice(1).replace(/([A-Z])/g, ' $1').trim();
};

export const spaceBeforeEachCapitalLetter = function (str: string): string {
    const array: string[] = [];
    let result = str[0].toUpperCase();
    for (let i = 1; i < str.length; i++) {
        if (str[i].toUpperCase() === str[i]) {
            array.push(" ");
        }
        array.push(str[i]);
    }

    array.map(char => result += char);
    return result;
};

/** Misc functions ****************************************************************************************************/

export const roundScore = function (score: any): any {
    if (score === "n/a" || score === null) {
        return "n/a";
    } else if (score === 100) {
        return score;
    } else {
        const rounded = Math.round(score);
        return (rounded > 99) ? 99 : rounded;
    }
}

export const scoreToPercentage = function (score: any): string {
    const percentage = roundScore(score);
    if (typeof percentage === "number") {
        return `${percentage}%`;
    } else {
        return percentage;
    }
}

export const sleep = async function (milliseconds: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const zeroNumber = function (num: number): string {
    const str = String(num);
    return str.length === 1 ? `0${str}` : str;
};

export const toTimeString = function (timestamp: number): string {
    const d = new Date(timestamp);
    const year = d.getFullYear();
    const month = zeroNumber(d.getMonth() + 1);
    const date = zeroNumber(d.getDate());
    const hours = zeroNumber(d.getHours());
    const minutes = zeroNumber(d.getMinutes());
    const seconds = zeroNumber(d.getSeconds());
    const milliseconds = (d.getMilliseconds() / 1000).toFixed(3).slice(2);
    return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}.${milliseconds}`
};

export const toDate = function (year: number, month: number, date: number): string {
    const months = ["FIXME", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${months[month]} ${date}, ${year}`;
};

/** APAM utilities ****************************************************************************************************/

export const toSafeUrl = function (url: string): string {
    return url
        .replace(/^.*:\/\//, "") // remove leading protocol (e.g. "https://")
        .replace(/[\W_]/g, "-"); // replace each non alphanumeric character with a dash
};

export const stripProtocolAndPath = function (url: string): string {
    return url
        .replace(/^(http(s)?:\/\/)?/, "")
        .replace(/\/.*$/, "");
};

export const stripProtocolFromUrl = function (url: string): string {
    return url
        .replace(/^(http(s)?:\/\/)?/, "");
};

export const getProtocolFromUrl = function (url: string): string {
    return url.replace(/:\/\/.*/, "")
};

export const isNotApplicable = function (id: any): boolean {
    const regexp = new RegExp("^(n|N) */ *(a|A)$");
    return regexp.test(`${id}`);
};

function _isCorrect(arg: any): boolean {
    if (Array.isArray(arg)) {
        for (let i = 0, imax = arg.length; i < imax; i++) {
            if (!_isCorrect(arg[i])) {
                return false;
            }
        }
        return true;
    } else if (typeof arg === "object" && arg !== null) {
        if ("actual" in arg && "expected" in arg) {
            if ("any" in arg) {
                if (arg.actual === null && arg.expected === null) {
                    return true;
                } else if (!Array.isArray(arg.actual) || !Array.isArray(arg.expected)) {
                    return false;
                } else if (arg.any === true) {
                    for (let i = 0, imax = arg.actual.length; i < imax; i++) {
                        if (arg.expected.includes(arg.actual[i])) {
                            return true;
                        }
                    }
                    return false;
                } else {
                    for (let i = 0, imax = arg.expected.length; i < imax; i++) {
                        if (!arg.actual.includes(arg.expected[i])) {
                            return false;
                        }
                    }
                    return true;
                }
            } else {
                return arg.actual === arg.expected;
            }
        } else {
            for (const attr in arg) {
                if (!_isCorrect(arg[attr])) {
                    return false;
                }
            }
            return true;
        }
    } else {
        return true;
    }
}

export const processActualExpectedObject = function (arg: any): any {
    if (Array.isArray(arg)) {
        const result: any[] = [];
        for (let i = 0, imax = arg.length; i < imax; i++) {
            let value = processActualExpectedObject(arg[i]);
            if (typeof value === "object" && value !== null && !Array.isArray(value)) {
                value = {
                    correct: _isCorrect(value),
                    ...value
                };
            }
            result.push(value);
        }
        return result;
    } else if (typeof arg === 'object' && arg !== null) {
        if ('actual' in arg && 'expected' in arg) {
            return {
                correct: _isCorrect(arg),
                ...arg
            };
        } else {
            const result: any = {};
            for (const attr in arg) {
                let value = processActualExpectedObject(arg[attr]);
                if (typeof value === "object" && value !== null && !Array.isArray(value)) {
                    value = {
                        correct: _isCorrect(value),
                        ...value
                    };
                }
                result[attr] = value;
            }
            return result;
        }
    } else {
        return arg;
    }
}

export const graphqlQuery = async function (route: string, query: string): Promise<any> {
    const mainStore = useMainStore();

    const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ query })
    };
    return fetch(route, options)
        .then(res => {
            return res.json()
        })
        .then(res => {
            if ("errors" in res) {
                for (const error of res.errors) {
                    console.log(`GraphQL ERROR: ${error.message}`);
                }
                if (res.errors.length === 1) {
                    mainStore.displayModalError("GraphQL Error", res.errors[0].message);
                } else if (res.errors.length > 1) {
                    const messages = res.errors.map((error: any) => error.message);
                    mainStore.displayModalError("GraphQL Errors", messages.join("\n\n", messages));
                }
            }

            if ("data" in res) {
                return res.data;
            } else {
                return null;
            }
        });
};

export const getSymbolFromCurrency = function (currency: string): string | undefined {
    const list: { [key: string]: string } = {
        AED: 'د.إ',
        AFN: '؋',
        ALL: 'L',
        AMD: '֏',
        ANG: 'ƒ',
        AOA: 'Kz',
        ARS: '$',
        AUD: '$',
        AWG: 'ƒ',
        AZN: '₼',
        BAM: 'KM',
        BBD: '$',
        BDT: '৳',
        BGN: 'лв',
        BHD: '.د.ب',
        BIF: 'FBu',
        BMD: '$',
        BND: '$',
        BOB: '$b',
        BOV: 'BOV',
        BRL: 'R$',
        BSD: '$',
        BTC: '₿',
        BTN: 'Nu.',
        BWP: 'P',
        BYN: 'Br',
        BYR: 'Br',
        BZD: 'BZ$',
        CAD: '$',
        CDF: 'FC',
        CHE: 'CHE',
        CHF: 'CHF',
        CHW: 'CHW',
        CLF: 'CLF',
        CLP: '$',
        CNH: '¥',
        CNY: '¥',
        COP: '$',
        COU: 'COU',
        CRC: '₡',
        CUC: '$',
        CUP: '₱',
        CVE: '$',
        CZK: 'Kč',
        DJF: 'Fdj',
        DKK: 'kr',
        DOP: 'RD$',
        DZD: 'دج',
        EEK: 'kr',
        EGP: '£',
        ERN: 'Nfk',
        ETB: 'Br',
        ETH: 'Ξ',
        EUR: '€',
        FJD: '$',
        FKP: '£',
        GBP: '£',
        GEL: '₾',
        GGP: '£',
        GHC: '₵',
        GHS: 'GH₵',
        GIP: '£',
        GMD: 'D',
        GNF: 'FG',
        GTQ: 'Q',
        GYD: '$',
        HKD: '$',
        HNL: 'L',
        HRK: 'kn',
        HTG: 'G',
        HUF: 'Ft',
        IDR: 'Rp',
        ILS: '₪',
        IMP: '£',
        INR: '₹',
        IQD: 'ع.د',
        IRR: '﷼',
        ISK: 'kr',
        JEP: '£',
        JMD: 'J$',
        JOD: 'JD',
        JPY: '¥',
        KES: 'KSh',
        KGS: 'лв',
        KHR: '៛',
        KMF: 'CF',
        KPW: '₩',
        KRW: '₩',
        KWD: 'KD',
        KYD: '$',
        KZT: '₸',
        LAK: '₭',
        LBP: '£',
        LKR: '₨',
        LRD: '$',
        LSL: 'M',
        LTC: 'Ł',
        LTL: 'Lt',
        LVL: 'Ls',
        LYD: 'LD',
        MAD: 'MAD',
        MDL: 'lei',
        MGA: 'Ar',
        MKD: 'ден',
        MMK: 'K',
        MNT: '₮',
        MOP: 'MOP$',
        MRO: 'UM',
        MRU: 'UM',
        MUR: '₨',
        MVR: 'Rf',
        MWK: 'MK',
        MXN: '$',
        MXV: 'MXV',
        MYR: 'RM',
        MZN: 'MT',
        NAD: '$',
        NGN: '₦',
        NIO: 'C$',
        NOK: 'kr',
        NPR: '₨',
        NZD: '$',
        OMR: '﷼',
        PAB: 'B/.',
        PEN: 'S/.',
        PGK: 'K',
        PHP: '₱',
        PKR: '₨',
        PLN: 'zł',
        PYG: 'Gs',
        QAR: '﷼',
        RMB: '￥',
        RON: 'lei',
        RSD: 'Дин.',
        RUB: '₽',
        RWF: 'R₣',
        SAR: '﷼',
        SBD: '$',
        SCR: '₨',
        SDG: 'ج.س.',
        SEK: 'kr',
        SGD: 'S$',
        SHP: '£',
        SLL: 'Le',
        SOS: 'S',
        SRD: '$',
        SSP: '£',
        STD: 'Db',
        STN: 'Db',
        SVC: '$',
        SYP: '£',
        SZL: 'E',
        THB: '฿',
        TJS: 'SM',
        TMT: 'T',
        TND: 'د.ت',
        TOP: 'T$',
        TRL: '₤',
        TRY: '₺',
        TTD: 'TT$',
        TVD: '$',
        TWD: 'NT$',
        TZS: 'TSh',
        UAH: '₴',
        UGX: 'USh',
        USD: '$',
        UYI: 'UYI',
        UYU: '$U',
        UYW: 'UYW',
        UZS: 'лв',
        VEF: 'Bs',
        VES: 'Bs.S',
        VND: '₫',
        VUV: 'VT',
        WST: 'WS$',
        XAF: 'FCFA',
        XBT: 'Ƀ',
        XCD: '$',
        XOF: 'CFA',
        XPF: '₣',
        XSU: 'Sucre',
        XUA: 'XUA',
        YER: '﷼',
        ZAR: 'R',
        ZMW: 'ZK',
        ZWD: 'Z$',
        ZWL: '$'
    }

    if (list[currency] !== undefined) {
        return list[currency];
    }
    return undefined;
}