<script setup lang="ts">
import { computed } from 'vue';
interface FieldProps {
    label: string | null
    value: string | number | null
    class?: string | string[]
    abbreviation?: boolean
    centered?: boolean
    expectedValue?: string | number | null
}

const props = withDefaults(defineProps<FieldProps>(), {
    abbreviation: false,
    centered: false
})

const hasExpectedValue = computed(() =>
    props.expectedValue !== undefined &&
    props.expectedValue !== props.value
)
</script>

<template>
    <td :class="[
        ...(Array.isArray(props.class) ? props.class : [props.class]),
        { center: centered }
    ]">
        <span class="fieldLabel">
            <template v-if="abbreviation">
                <abbr>{{ label }}</abbr>
            </template>
            <template v-else>
                {{ label }}
            </template>
        </span>
        <span v-if="hasExpectedValue" :title="`Expected: ${expectedValue}`">
            {{ value }}
        </span>
        <span v-else>
            {{ value }}
        </span>
    </td>
</template>

<style scoped>
td {
    padding: 0.5em;
    vertical-align: middle;
}

.fieldLabel {
    font-weight: 500;
    margin-right: 0.5em;
}

.center {
    text-align: center;
}

abbr {
    text-decoration: none;
    font-weight: bold;
}
</style>