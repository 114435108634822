import { h } from 'vue'
import BaseHeader from '@/components/templates/BaseHeader.vue'
import BaseField from '@/components/templates/BaseField.vue'
import { templateRegistry } from '@/components/templates/registry'
import type { TemplateFunction, TemplateParams } from '@/types/shared.types'
import { getScoreClass, toScore, toString } from '@/components/templates/template-utils';

// Analytics4 Headers
function renderAnalytics4UncategorizedHeaders() {
  return [
    () => h(BaseHeader, { column: 'score', label: 'Score' }),
    () => h(BaseHeader, { column: 'customDimensions', label: 'Cust. Dim.' }),
    () => h(BaseHeader, { column: 'subpropertycustomDimensions', label: 'Subproperty Cust. Dim.' }),
    () => h(BaseHeader, { column: 'channelGroups', label: 'Channel Groups' })
  ]
}

// Analytics4 Fields
function renderAnalytics4UncategorizedFields(params: TemplateParams) {
  const { item } = params
  let order = 100

  return [
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.score)],
      label: 'Score:',
      value: toScore(item.score)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.propertyCustomDimensions)],
      label: 'Property CD:',
      value: toString(item.propertyCustomDimensions)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.subpropertyCustomDimensions)],
      label: 'Subproperty CD:',
      value: toString(item.subpropertyCustomDimensions)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.propertyChannelGroups)],
      label: 'Channel Groups:',
      value: toString(item.propertyChannelGroups)
    })
  ]
}

// TagManager Headers
function renderTagManagerUncategorizedHeaders(params: TemplateParams) {
  const featureName = `${params.listName?.replace("-", ".")}.floodlightTags`
  const isFloodlightTagsEnabled = params.activatedFeatures?.includes(featureName)

  const headers = [
    () => h(BaseHeader, { column: 'score', label: 'Score' }),
    () => h(BaseHeader, { column: 'ga4Tags', label: 'CDO GA4 Tags' }),
    () => h(BaseHeader, { column: 'nonGa4Tags', label: 'Non CDO GA4 Tags' })
  ]

  if (isFloodlightTagsEnabled) {
    headers.push(() => h(BaseHeader, {
      column: 'floodlightTags',
      label: 'CDO Floodlight Tags'
    }))
  }

  return headers
}

// TagManager Fields
function renderTagManagerUncategorizedFields(params: TemplateParams) {
  const { item, activatedFeatures, listName } = params
  const featureName = `${listName?.replace("-", ".")}.floodlightTags`
  const isFloodlightTagsEnabled = activatedFeatures?.includes(featureName)
  let order = 100

  if (item.score === null) {
    return [
      () => h(BaseField, {
        class: [`order${order++}`, 'null'],
        label: 'Score:',
        value: toScore(null)
      }),
      () => h(BaseField, {
        class: [`order${order++}`, 'null', 'center'],
        label: 'CDO GA4 Tags:',
        value: toString(null)
      }),
      () => h(BaseField, {
        class: [`order${order++}`, 'null', 'center'],
        label: 'Non-CDO GA4 Tags:',
        value: toString(null)
      }),
      ...(isFloodlightTagsEnabled ? [
        () => h(BaseField, {
          class: [`order${order++}`, 'null', 'center'],
          label: 'CDO Floodlight Tags:',
          value: toString(null)
        })
      ] : [])
    ]
  }

  const fields = [
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.score)],
      label: 'Score:',
      value: toScore(item.score)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, item.ga4Tags !== null ? getScoreClass(item.ga4Tags) : 'bad', 'center'],
      label: 'CDO GA4 Tags:',
      value: toString(item.ga4Tags)
    }),
    () => h(BaseField, {
      class: [
        `order${order++}`,
        item.nonGa4Tags && item.nonGa4Tags.actual === item.nonGa4Tags.expected ? 'good' : 'bad',
        'center'
      ],
      label: 'Non-CDO GA4 Tags:',
      value: renderComparisonValue(item.nonGa4Tags)
    })
  ]

  if (isFloodlightTagsEnabled) {
    fields.push(() => h(BaseField, {
      class: [`order${order++}`, item.floodlightTags !== null ? getScoreClass(item.floodlightTags) : 'bad', 'center'],
      label: 'CDO Floodlight Tags:',
      value: toString(item.floodlightTags)
    }))
  }

  return fields
}

function renderComparisonValue(value: {
  actual: string | number | null,
  expected: string | number | null
} | null): string | null {
  if (!value || value.actual === null) {
    return toString(null)
  }

  if (value.expected !== null && value.expected !== value.actual) {
    return toString(value.actual)
  }

  return toString(value.actual)
}

// CampaignManager Headers
function renderCampaignManagerUncategorizedHeaders(params: TemplateParams) {
  const featureName = `${params.listName?.replace("-", ".")}.floodlightActivities`
  const isFloodlightActivitiesEnabled = params.activatedFeatures?.includes(featureName)

  const headers = [
    () => h(BaseHeader, { column: 'score', label: 'Score' }),
    () => h(BaseHeader, { column: 'floodlightConfiguration', label: 'Cust. Floodlight vars' })
  ]

  if (isFloodlightActivitiesEnabled) {
    headers.push(() => h(BaseHeader, {
      column: 'floodlightActivities',
      label: 'CDO Activities'
    }))
  }

  return headers
}

// CampaignManager Fields
function renderCampaignManagerUncategorizedFields(params: TemplateParams) {
  const { item, listName, activatedFeatures } = params
  const featureName = `${listName?.replace("-", ".")}.floodlightActivities`
  const isFloodlightActivitiesEnabled = activatedFeatures?.includes(featureName)
  let order = 100

  const fields = [
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.score)],
      label: 'Score:',
      value: toScore(item.score)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.floodlightConfiguration)],
      label: 'Cust. Floodlight vars:',
      value: toString(item.floodlightConfiguration)
    })
  ]

  if (isFloodlightActivitiesEnabled) {
    fields.push(() => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.floodlightActivities)],
      label: 'Cust. Floodlight acts:',
      value: toString(item.floodlightActivities)
    }))
  }

  return fields
}

// Register templates
export function registerToolUncategorizedTemplates() {
  // Headers
  templateRegistry.register('headers', 'list-analytics4-uncategorized',
    renderAnalytics4UncategorizedHeaders as TemplateFunction)

  templateRegistry.register('headers', 'list-tagmanager-uncategorized',
    renderTagManagerUncategorizedHeaders as TemplateFunction)

  templateRegistry.register('headers', 'list-campaignmanager-uncategorized',
    renderCampaignManagerUncategorizedHeaders as TemplateFunction)

  // Fields
  templateRegistry.register('fields', 'list-analytics4-uncategorized',
    renderAnalytics4UncategorizedFields as TemplateFunction)

  templateRegistry.register('fields', 'list-tagmanager-uncategorized',
    renderTagManagerUncategorizedFields as TemplateFunction)

  templateRegistry.register('fields', 'list-campaignmanager-uncategorized',
    renderCampaignManagerUncategorizedFields as TemplateFunction)
}