import { h } from 'vue'
import BaseHeader from '@/components/templates/BaseHeader.vue'
import BaseField from '@/components/templates/BaseField.vue'
import { templateRegistry } from '@/components/templates/registry'
import type { TemplateParams } from '@/types/shared.types'
import { getScoreClass, toScore, toString } from '@/components/templates/template-utils'

// Analytics4 Headers
function renderAnalytics4DataPrivacyHeaders() {
  return [
    () => h(BaseHeader, { column: 'score', label: 'Score' }),
    () => h(BaseHeader, { column: 'propertyDataRetentionDuration', label: 'TTL' }),
    () => h(BaseHeader, { column: 'propertyDataRetentionResetOnNewActivity', label: 'Reset' }),
    () => h(BaseHeader, { column: 'propertyDataCollectionStatus', label: 'Google Signal Status' }),
    () => h(BaseHeader, { column: 'subpropertyDataRetentionDuration', label: 'Subproperty TTL' }),
    () => h(BaseHeader, { column: 'subpropertyDataRetentionResetOnNewActivity', label: 'Subproperty Reset' }),
    () => h(BaseHeader, { column: 'subpropertyDataCollectionStatus', label: 'Subproperty Google Signal Status' }),
  ]
}

// TagManager Headers
function renderTagManagerDataPrivacyHeaders() {
  return [
    () => h(BaseHeader, { column: 'score', label: 'Score' }),
    () => h(BaseHeader, { column: 'ga4', label: 'GA 4' }),
    () => h(BaseHeader, { column: 'floodlight', label: 'Floodlight' }),
    () => h(BaseHeader, { column: 'googleAds', label: 'Google Ads' }),
    () => h(BaseHeader, { column: 'other', label: 'Other' })
  ]
}

// Analytics4 Fields
function renderAnalytics4DataPrivacyFields(params: TemplateParams) {
  const { item } = params
  let order = 100

  return [
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.score)],
      label: 'Score:',
      value: toScore(item.score)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.propertyDataRetentionDuration)],
      label: 'Retention TTL:',
      value: item.propertyDataRetentionDuration ? toString(item.propertyDataRetentionDuration.actual) : 'n/a'
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.propertyDataRetentionResetOnNewActivity)],
      label: 'Reset on new activity:',
      value: item.propertyDataRetentionResetOnNewActivity ? toString(item.propertyDataRetentionResetOnNewActivity.actual) : 'n/a'
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.propertyDataCollectionStatus)],
      label: 'Google Signal Status:',
      value: item.propertyDataCollectionStatus ? toString(item.propertyDataCollectionStatus.actual) : 'n/a'
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.subpropertyDataRetentionDuration)],
      label: 'Subproperty TTL:',
      value: item.subpropertyDataRetentionDuration ? toString(item.subpropertyDataRetentionDuration.actual) : 'n/a'
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.subpropertyDataRetentionResetOnNewActivity)],
      label: 'Subproperty Reset:',
      value: item.subpropertyDataRetentionResetOnNewActivity ? toString(item.subpropertyDataRetentionResetOnNewActivity.actual) : 'n/a'
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.subpropertyDataCollectionStatus)],
      label: 'Subproperty GSS:',
      value: item.subpropertyDataCollectionStatus ? toString(item.subpropertyDataCollectionStatus.actual) : 'n/a'
    }),
  ]
}

// TagManager Fields
function renderTagManagerDataPrivacyFields(params: TemplateParams) {
  const { item } = params
  let order = 100

  return [
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.score)],
      label: 'Score:',
      value: toScore(item.score)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.ga4), 'center'],
      label: 'GA 4:',
      value: toString(item.ga4)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.floodlight), 'center'],
      label: 'Floodlight:',
      value: toString(item.floodlight)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.googleAds), 'center'],
      label: 'Google Ads:',
      value: toString(item.googleAds)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.other), 'center'],
      label: 'Other:',
      value: toString(item.other)
    })
  ]
}

export function registerToolDataPrivacyTemplates() {
  // Headers
  templateRegistry.register('headers', 'list-analytics4-dataprivacy',
    () => renderAnalytics4DataPrivacyHeaders())

  templateRegistry.register('headers', 'list-tagmanager-dataprivacy',
    () => renderTagManagerDataPrivacyHeaders())

  // Fields
  templateRegistry.register('fields', 'list-analytics4-dataprivacy',
    (params: TemplateParams) => renderAnalytics4DataPrivacyFields(params))

  templateRegistry.register('fields', 'list-tagmanager-dataprivacy',
    (params: TemplateParams) => renderTagManagerDataPrivacyFields(params))
}