import { type TemplateFunction, type TemplateParams } from '@/types/shared.types'
import type { VNode } from 'vue'

export class TemplateRegistry {
    private headers = new Map<string, TemplateFunction>()
    private fields = new Map<string, TemplateFunction>()

    register(type: 'headers' | 'fields', key: string, template: TemplateFunction) {
        const registry = type === 'headers' ? this.headers : this.fields
        registry.set(key, template)
    }

    get(type: 'headers' | 'fields', key: string, params?: TemplateParams): (() => VNode)[] {
        const template = (type === 'headers' ? this.headers : this.fields).get(key)
        if (!template) return []
        return typeof template === 'function' ? template(params || { key }) : template
    }
}

export const templateRegistry = new TemplateRegistry()