<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { useMainStore } from '@/stores/main'
import { useToolsStore } from '@/stores/tools'
import { GoogleClient } from '@/google-client/google'
import Button from 'primevue/button'
import { APAM_DISPLAY_AUTHENT_LOGS } from '@/config/environment-front-end'

// Store instances
const mainStore = useMainStore()
const toolsStore = useToolsStore()

// Reactive state
let offline = mainStore.app.offline
const inProgress = ref(false)
const googleClientAuth2Loaded = ref(window.gapi !== undefined)
const googleClient = new GoogleClient()

// Constants
const actionSignInLabel = 'signin'
const actionLoadOauth2Label = 'use oauth 2.0'

// Computed properties
const isAuthValid = computed(() => toolsStore.isAuthenticationTimeValid())
const buttonDisabled = computed(() => offline || inProgress.value)
const buttonLabel = computed(() => {
  if (isSafari() && !googleClientAuth2Loaded.value) {
    return actionLoadOauth2Label
  }
  return actionSignInLabel
})
const showLoadingDots = computed(() => inProgress.value ? '...' : '')


// Utility functions
const isSafari = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase()
  return userAgent.includes('safari') && !userAgent.includes('chrome')
}

// Event handlers
const handleGoogleClientInitializedFailed = () => {
  inProgress.value = false
}

const handleGsiInitialized = (e: Event) => {
  if (APAM_DISPLAY_AUTHENT_LOGS) {
    console.log('[AUTENT STEPS]GSI initialized in event', e)
  }
  googleClient.gsiClientRequestToken()
}

const handleGsiConnected = (e: Event) => {
  if (APAM_DISPLAY_AUTHENT_LOGS) {
    console.log('[AUTENT STEPS]GSI connected in event', e)
  }

  const eventAuthLoaded = new Event('GoogleClientAuth2Loaded')
  const eventGCInitialized = new Event('GoogleClientInitialized')
  document.dispatchEvent(eventAuthLoaded)
  document.dispatchEvent(eventGCInitialized)

  mainStore.showToast('Signing successful', 'success')
}

// Main authentication logic
const loadOauth = async (): Promise<void> => {
  console.log("ici");

  mainStore.setLoaderVisible(true)
  inProgress.value = true
  try {
    if (window.google === undefined) {
      await googleClient.loadAndExecuteGSI()
    } else if (window.apam?.gsiClient === undefined) {
      await googleClient.gsiInit()
    } else {
      await googleClient.gsiClientRequestToken()
    }
  } catch (e) {
    console.error(e)
    mainStore.showToast('Failed to load OAuth', 'error')
    inProgress.value = false
  }
}

// Authentication logic
const signIn = async (): Promise<void> => {
  mainStore.setLoaderVisible(true)
  inProgress.value = true

  try {
    if (!toolsStore.isAuthenticationTimeValid()) {
      await googleClient.signOut()
    }

    mainStore.showToast('Signing in…', 'info')

    try {
      googleClient.signIn()

      if (APAM_DISPLAY_AUTHENT_LOGS) {
        console.log("[AUTHENT STEPS] Success! User is signed in.")
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.error === 'popup_blocked_by_browser') {
        mainStore.displayModalError(
          'Error',
          "Your browser is blocking popups, which prevents Google's identification screen from showing.\n\nPlease enable popups and try again."
        )
      } else if (e.error === 'popup_closed_by_user') {
        mainStore.displayModalError(
          'Error',
          "You closed Google's identification screen. Action cannot be completed without user identification."
        )
      } else {
        mainStore.displayModalException(e)
      }

      // Fire event to hide loader
      document.dispatchEvent(new Event('GoogleClientExceptionFired'))
    }
  } catch (e) {
    console.error(e)
    return googleClient.loadAndExecuteGSI()
  } finally {
    inProgress.value = false
  }
}

const handleClick = () => {
  if (isSafari() && !googleClientAuth2Loaded.value) {
    loadOauth()
  } else {
    signIn()
  }
}

// Lifecycle hooks
onMounted(() => {
  document.addEventListener('GoogleClientInitializedFailed', handleGoogleClientInitializedFailed)
  document.addEventListener('googleGsiInitialized', handleGsiInitialized)
  document.addEventListener('google_gsi_connected', handleGsiConnected)
})

onUnmounted(() => {
  document.addEventListener('GoogleClientInitializedFailed', handleGoogleClientInitializedFailed)
  document.addEventListener('googleGsiInitialized', handleGsiInitialized)
  document.addEventListener('google_gsi_connected', handleGsiConnected)
})

// Watchers
watch(
  () => mainStore.app.offline,
  (newValue) => {
    offline = newValue
  },
  { immediate: true }
)
</script>

<template>
  <div v-if="!isAuthValid">
    <Button outlined :disabled="buttonDisabled" :loading="inProgress" @click="handleClick">
      {{ buttonLabel }}{{ showLoadingDots }}
    </Button>
  </div>
</template>