import {
  APAM_ENVIRONMENT,
  APAM_ENVIRONMENT_LOCAL_VALUE,
  APAM_ENVIRONMENT_DEV_VALUE,
  APAM_PROJECT_ID,
  APAM_INVENTORY_BUCKET_NAME,
} from "./environment-front-end";

export const getBackEndRootUrl = function () {
  let url = '/'
  if (APAM_ENVIRONMENT === APAM_ENVIRONMENT_LOCAL_VALUE) {
    const loc = window.location;
    url = `${loc.protocol}//${loc.hostname}:8080/`;
  }
  return url
};

// Version number of "data" objects (e.g. /google-tag-manager/data or /google-analytics/data, etc…)
export const APAM_VERSION = 97;

// How long before we actively check the user's authentication status:
// export const APAM_AUTHENTICATION_DURATION = 10 * 60 * 1000; // 10 minutes, in milliseconds
// export const APAM_AUTHENTICATION_DURATION = 24 * 60 * 60 * 1000; // one day, in milliseconds
export const APAM_AUTHENTICATION_DURATION = 30 * 24 * 60 * 60 * 1000; // one month, in milliseconds

// How long before we allow fetching the same list a second time in a row:
export const APAM_FETCH_TIMEOUT = 5 * 1000; // 5 seconds, in milliseconds

// How long before we fetch a new list:
export const APAM_LIST_MAX_AGE = 1 * 60 * 1000; // 1 minute, in milliseconds

// How long before we update the entire "data" object with all the lists:
export const APAM_DATA_MAX_AGE = 24 * 60 * 60 * 1000; // 1 day, in milliseconds

// How long before we nag the user again about updating the app:
export const APAM_DISMISSAL_MAX_AGE = 1 * 60 * 1000; // 1 minute, in milliseconds

export const APAM_DEFAULT_SORTING_LIST = "tools";
export const APAM_DEFAULT_SORTING_COLUMN = "brand";
export const APAM_DEFAULT_SORTING_ORDER = "ascending";

export const APAM_ADMIN_WHITE_LIST = ['thomas.bianconi@loreal.com', 'jochen.rousse@loreal.com', 'vincent.denais@loreal.com'];

// Enable the interactive user interface "GraphiQL" (GraphQL with an extra "i") in these environments:
export const GRAPHIQL_ENVIRONMENTS = [
  APAM_ENVIRONMENT_LOCAL_VALUE,
  APAM_ENVIRONMENT_DEV_VALUE
];
export const CLOUD_STORAGE_ENV = [
  APAM_PROJECT_ID,
  APAM_INVENTORY_BUCKET_NAME,
];

