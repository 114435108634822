import { h } from 'vue'
import BaseHeader from '@/components/templates/BaseHeader.vue'
import BaseField from '@/components/templates/BaseField.vue'
import { templateRegistry } from '@/components/templates/registry'
import type { TemplateParams } from '@/types/shared.types'
import { getScoreClass, toScore, isThisGroupActivated } from '@/components/templates/template-utils'

const ABBREVIATIONS = {
    tagmanager: 'GTM',
    analytics4: 'GA4',
    campaignmanager: 'CM'
}

function renderToolHeaders(params: TemplateParams) {
    const abbr = params.tool ? ABBREVIATIONS[params.tool as keyof typeof ABBREVIATIONS] : ''
    const headers = [
        () => h(BaseHeader, {
            column: 'score',
            label: `${abbr ? `${abbr} ` : ''}Score`
        })
    ]

    if (isThisGroupActivated('structure', params)) {
        headers.push(() => h(BaseHeader, {
            column: 'structureScore',
            label: 'Structure'
        }))
    }

    if (isThisGroupActivated('dataprivacy', params)) {
        headers.push(() => h(BaseHeader, {
            column: 'dataprivacyScore',
            label: 'Data Privacy'
        }))
    }

    if (isThisGroupActivated('uncategorized', params)) {
        headers.push(() => h(BaseHeader, {
            column: 'uncategorizedScore',
            label: 'Uncategorized'
        }))
    }

    return headers
}

function renderToolFields(params: TemplateParams) {
    const { item, tool } = params
    let order = 100
    const abbr = tool ? ABBREVIATIONS[tool as keyof typeof ABBREVIATIONS] : ''

    const fields = [
        () => h(BaseField, {
            class: [`order${order++}`, getScoreClass(item.score)],
            label: `${abbr} Score:`,
            value: toScore(item.score)
        })
    ]

    if (isThisGroupActivated('structure', params)) {
        fields.push(() => h(BaseField, {
            class: [`order${order++}`, getScoreClass(item.structureScore)],
            label: 'Structure:',
            value: toScore(item.structureScore)
        }))
    }

    if (isThisGroupActivated('dataprivacy', params)) {
        fields.push(() => h(BaseField, {
            class: [`order${order++}`, getScoreClass(item.dataprivacyScore)],
            label: 'Data Privacy:',
            value: toScore(item.dataprivacyScore)
        }))
    }

    if (isThisGroupActivated('uncategorized', params)) {
        fields.push(() => h(BaseField, {
            class: [`order${order++}`, getScoreClass(item.uncategorizedScore)],
            label: 'Uncategorized:',
            value: toScore(item.uncategorizedScore)
        }))
    }

    return fields
}

export function registerToolOverviewTemplates() {
    // Topic overview headers
    templateRegistry.register('headers', 'list-topics',
        (params: TemplateParams) => renderToolHeaders(params))

    // Tool specific templates
    const tools = ['analytics4', 'tagmanager', 'campaignmanager']
    tools.forEach(tool => {
        templateRegistry.register('headers', `list-${tool}`,
            (params: TemplateParams) => renderToolHeaders(params))

        templateRegistry.register('fields', `list-${tool}`,
            (params: TemplateParams) => renderToolFields(params))
    })

    templateRegistry.register('fields', 'list-topics', (params: TemplateParams) => renderToolFields(params))
}