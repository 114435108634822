import { h } from 'vue'
import BaseHeader from '@/components/templates/BaseHeader.vue'
import BaseField from '@/components/templates/BaseField.vue'
import { templateRegistry } from '@/components/templates/registry'
import type { TemplateParams } from '@/types/shared.types'
import { getScoreClass, toScore } from '@/components/templates/template-utils'

function renderTopicHeaders() {
  return [
    () => h(BaseHeader, { column: 'score', label: 'Score' }),
    () => h(BaseHeader, { column: 'analytics4Score', label: 'GA4' }),
    () => h(BaseHeader, { column: 'tagmanagerScore', label: 'GTM' }),
    () => h(BaseHeader, { column: 'campaignmanagerScore', label: 'CM' })
  ]
}

function renderTopicFields(params: TemplateParams) {
  const { item } = params
  let order = 100

  return [
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.score)],
      label: 'Score:',
      value: toScore(item.score)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.analytics4Score)],
      label: 'Analytics 4:',
      value: toScore(item.analytics4Score)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.tagmanagerScore)],
      label: 'Tag Manager:',
      value: toScore(item.tagmanagerScore)
    }),
    () => h(BaseField, {
      class: [`order${order++}`, getScoreClass(item.campaignmanagerScore)],
      label: 'Campaign Manager:',
      value: toScore(item.campaignmanagerScore)
    })
  ]
}

export function registerTopicOverviewTemplates() {
  // Headers registration
  templateRegistry.register('headers', 'tools', renderTopicHeaders())

  // Topic specific headers
  const topics = ['structure', 'dataprivacy', 'uncategorized']
  topics.forEach(topic => {
    templateRegistry.register('headers', topic, renderTopicHeaders())
  })

  // Fields registration
  templateRegistry.register('fields', 'tools',
    (params: TemplateParams) => renderTopicFields(params))

  topics.forEach(topic => {
    templateRegistry.register('fields', topic, (params: TemplateParams) => renderTopicFields(params))
  })
}