import '@/styles/main.css'
import '@/styles/variables.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import App from '@/App.vue'
import { registerAllTemplates } from '@/components/templates'
import router from '@/router'
import PrimeVue from 'primevue/config'
import Material from '@primevue/themes/material';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice'

import 'primeicons/primeicons.css'
import '/node_modules/primeflex/primeflex.css'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

registerAllTemplates()
const app = createApp(App)

app.use(pinia)
app.use(router)
app.use(PrimeVue, {
    ripple: true,
    theme: {
        preset: Material,
        options: {
            darkModeSelector: 'none',
        }
    }
})
app.use(ToastService)
app.use(DialogService)

app.mount('#app')