export const tools = {
  "tools": {
    "items": [
      [
        1588174978362,
        76.09,
        3,
        76.22,
        77,
        100,
        51.28
      ],
      [
        1588174930942,
        45.81,
        3,
        48.18,
        62.42,
        50,
        25
      ],
      [
        1588174907674,
        59.65,
        3,
        67.1,
        78.94,
        100,
        0
      ],
      [
        1588174907446,
        99.65,
        3,
        99.55,
        98.94,
        100,
        100
      ],
      [
        1588174908363,
        68.37,
        3,
        66.48,
        55.11,
        100,
        50
      ],
      [
        1588174908179,
        59.03,
        3,
        66.41,
        78.94,
        98.15,
        0
      ],
      [
        1588174907245,
        34.92,
        3,
        37.75,
        54.75,
        50,
        0
      ],
      [
        1588174930797,
        46.16,
        3,
        43.38,
        26.71,
        61.77,
        50
      ],
      [
        1588174936402,
        52.64,
        3,
        51.57,
        45.11,
        50,
        62.82
      ],
      [
        1588174936747,
        72.27,
        3,
        71.49,
        66.81,
        100,
        50
      ],
      [
        1588174937724,
        69.38,
        3,
        73.64,
        74.96,
        97.92,
        35.26
      ],
      [
        1588174936655,
        99.88,
        3,
        99.87,
        99.65,
        100,
        100
      ],
      [
        1588174937282,
        99.76,
        3,
        99.73,
        99.29,
        100,
        100
      ],
      [
        1588174937383,
        62.7,
        3,
        59.18,
        38.09,
        100,
        50
      ],
      [
        1588174936909,
        43.69,
        3,
        49.15,
        31.06,
        100,
        0
      ],
      [
        1588174937198,
        99.15,
        3,
        99.36,
        100,
        100,
        97.44
      ],
      [
        1588174937690,
        29.36,
        3,
        30.61,
        38.09,
        50,
        0
      ],
      [
        1588174937505,
        46.03,
        3,
        44.9,
        38.09,
        50,
        50
      ],
      [
        1588174937942,
        70.93,
        3,
        75.06,
        74.96,
        100,
        37.82
      ],
      [
        1588174937966,
        82.98,
        3,
        85.26,
        98.94,
        50,
        100
      ],
      [
        1588174936884,
        73.18,
        3,
        76.08,
        71.77,
        97.78,
        50
      ],
      [
        1588174938092,
        70.27,
        3,
        74.33,
        74.96,
        98.04,
        37.82
      ],
      [
        1588174936466,
        46.03,
        3,
        44.9,
        38.09,
        50,
        50
      ],
      [
        1588174938138,
        48.25,
        3,
        47.75,
        44.75,
        50,
        50
      ],
      [
        1588174937138,
        42.09,
        3,
        41.57,
        38.44,
        50,
        37.82
      ],
      [
        1588174911061,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174910705,
        97.46,
        3,
        97.14,
        92.37,
        100,
        100
      ],
      [
        1588174910318,
        96.76,
        3,
        96.68,
        92.85,
        100,
        97.44
      ],
      [
        1588174910657,
        66.61,
        3,
        68.69,
        77,
        72.84,
        50
      ],
      [
        1588174910550,
        73.61,
        3,
        76.56,
        70.82,
        100,
        50
      ],
      [
        1588174911325,
        88.24,
        3,
        86.77,
        85.22,
        79.49,
        100
      ],
      [
        1588174942807,
        51.03,
        3,
        49.59,
        40.97,
        62.12,
        50
      ],
      [
        1588174975977,
        70.68,
        3,
        73.02,
        87.05,
        75,
        50
      ],
      [
        1588174974581,
        99.84,
        3,
        99.82,
        99.52,
        100,
        100
      ],
      [
        1588174974664,
        80.43,
        3,
        81.99,
        91.3,
        100,
        50
      ],
      [
        1588174977158,
        70.04,
        3,
        75.67,
        85.12,
        100,
        25
      ],
      [
        1588174976637,
        72.42,
        3,
        75.26,
        92.27,
        100,
        25
      ],
      [
        1588174976413,
        54.72,
        3,
        56.06,
        64.15,
        100,
        0
      ],
      [
        1588174975313,
        63.77,
        3,
        67.7,
        91.3,
        100,
        0
      ],
      [
        1588174975969,
        51.76,
        3,
        52.26,
        55.27,
        50,
        50
      ],
      [
        1588174976719,
        72.1,
        3,
        77.99,
        91.3,
        100,
        25
      ],
      [
        1588174975676,
        69.54,
        3,
        73.5,
        91.3,
        79.49,
        37.82
      ],
      [
        1588174974149,
        99.84,
        3,
        99.82,
        99.52,
        100,
        100
      ],
      [
        1588174974390,
        99.68,
        3,
        99.64,
        99.03,
        100,
        100
      ],
      [
        1588174974388,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174975434,
        78.21,
        3,
        79.13,
        84.64,
        100,
        50
      ],
      [
        1588174976262,
        69.88,
        3,
        75.49,
        84.64,
        100,
        25
      ],
      [
        1588174977273,
        90.81,
        3,
        93.11,
        100,
        100,
        72.44
      ],
      [
        1588174975041,
        68.47,
        3,
        71.73,
        91.3,
        75,
        39.11
      ],
      [
        1588174975587,
        69.57,
        3,
        73.54,
        91.4,
        79.49,
        37.82
      ],
      [
        1588174976254,
        69.91,
        3,
        72.03,
        84.73,
        100,
        25
      ],
      [
        1588174973944,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174977043,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174975178,
        94.88,
        3,
        94.24,
        84.64,
        100,
        100
      ],
      [
        1588174973837,
        83.01,
        3,
        85.3,
        99.03,
        50,
        100
      ],
      [
        1588174976958,
        70.87,
        3,
        76.6,
        91.3,
        96.3,
        25
      ],
      [
        1588174974231,
        69.02,
        3,
        74.53,
        84.15,
        97.92,
        25
      ],
      [
        1588174975821,
        69.57,
        3,
        73.54,
        91.4,
        79.49,
        37.82
      ],
      [
        1588174974787,
        56.76,
        3,
        57.5,
        61.93,
        83.34,
        25
      ],
      [
        1588174976460,
        35.09,
        3,
        37.97,
        55.27,
        50,
        0
      ],
      [
        1588174975412,
        58.96,
        3,
        63.21,
        85.22,
        66.67,
        25
      ],
      [
        1588174976830,
        99.84,
        3,
        99.82,
        99.52,
        100,
        100
      ],
      [
        1588174973852,
        79.95,
        3,
        81.37,
        89.86,
        100,
        50
      ],
      [
        1588174975174,
        83.21,
        3,
        84.37,
        91.3,
        100,
        58.34
      ],
      [
        1588174930693,
        50.3,
        3,
        48.56,
        38.09,
        50,
        62.82
      ],
      [
        1588174930622,
        43.81,
        3,
        42.04,
        31.42,
        50,
        50
      ],
      [
        1588174930536,
        92.81,
        3,
        91.91,
        98.94,
        79.49,
        100
      ],
      [
        1588174930317,
        58.09,
        3,
        60.4,
        74.26,
        50,
        50
      ],
      [
        1588174978501,
        61.62,
        3,
        62.8,
        69.86,
        65,
        50
      ],
      [
        1588174898364,
        38.33,
        3,
        38.57,
        40,
        50,
        25
      ],
      [
        1588174957752,
        81.45,
        3,
        72.17,
        44.35,
        100,
        100
      ],
      [
        1588174958761,
        83.67,
        3,
        75.5,
        51.01,
        100,
        100
      ],
      [
        1588174960073,
        83.67,
        3,
        79,
        51.01,
        100,
        100
      ],
      [
        1588174961257,
        56.45,
        3,
        54.72,
        44.35,
        100,
        25
      ],
      [
        1588174961266,
        85.73,
        3,
        78.6,
        57.2,
        100,
        100
      ],
      [
        1588174961565,
        48.12,
        3,
        47.58,
        44.35,
        100,
        0
      ],
      [
        1588174891286,
        81.13,
        3,
        71.69,
        43.38,
        100,
        100
      ],
      [
        1588174962564,
        81.45,
        3,
        72.17,
        44.35,
        100,
        100
      ],
      [
        1588174958885,
        81.45,
        3,
        72.17,
        44.35,
        100,
        100
      ],
      [
        1588174961442,
        76.32,
        3,
        69.56,
        44.35,
        84.62,
        100
      ],
      [
        1588174961747,
        99.68,
        3,
        99.58,
        99.03,
        100,
        100
      ],
      [
        1588174961439,
        92.85,
        3,
        90.81,
        78.55,
        100,
        100
      ],
      [
        1588174962376,
        83.67,
        3,
        75.5,
        51.01,
        100,
        100
      ],
      [
        1588174962193,
        76.32,
        3,
        69.56,
        44.35,
        84.62,
        100
      ],
      [
        1588174960260,
        81.45,
        3,
        72.17,
        44.35,
        100,
        100
      ],
      [
        1588174958123,
        81.45,
        3,
        72.17,
        44.35,
        100,
        100
      ],
      [
        1588174942852,
        30.81,
        3,
        34.66,
        25.75,
        66.67,
        0
      ],
      [
        1588174961437,
        49.46,
        3,
        55.64,
        68.89,
        79.49,
        0
      ],
      [
        1588174891216,
        42.4,
        3,
        47.7,
        27.2,
        100,
        0
      ],
      [
        1588174961437,
        49.62,
        3,
        55.82,
        69.37,
        79.49,
        0
      ],
      [
        1588174959731,
        81.45,
        3,
        72.17,
        44.35,
        100,
        100
      ],
      [
        1588174962377,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174959339,
        81.29,
        3,
        71.93,
        43.86,
        100,
        100
      ],
      [
        1588174959339,
        81.29,
        3,
        71.93,
        43.86,
        100,
        100
      ],
      [
        1588174959445,
        64.78,
        3,
        63.84,
        44.35,
        100,
        50
      ],
      [
        1588174958525,
        81.45,
        3,
        72.17,
        44.35,
        100,
        100
      ],
      [
        1588174962192,
        81.45,
        3,
        72.17,
        44.35,
        100,
        100
      ],
      [
        1588174958389,
        85.73,
        3,
        78.6,
        57.2,
        100,
        100
      ],
      [
        1588174960485,
        81.45,
        3,
        72.17,
        44.35,
        100,
        100
      ],
      [
        1588174957922,
        75.89,
        3,
        63.84,
        27.68,
        100,
        100
      ],
      [
        1588174957717,
        81.45,
        3,
        72.17,
        44.35,
        100,
        100
      ],
      [
        1588174959580,
        83.67,
        3,
        75.5,
        51.01,
        100,
        100
      ],
      [
        1588174960173,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174962744,
        85.89,
        3,
        84.13,
        57.68,
        100,
        100
      ],
      [
        1588174961836,
        97.29,
        3,
        95.94,
        91.88,
        100,
        100
      ],
      [
        1588174961040,
        95.07,
        3,
        92.61,
        85.22,
        100,
        100
      ],
      [
        1588174959869,
        81.29,
        3,
        71.93,
        43.86,
        100,
        100
      ],
      [
        1588174961748,
        90.47,
        3,
        87.74,
        71.4,
        100,
        100
      ],
      [
        1588174960591,
        83.83,
        3,
        79.21,
        51.5,
        100,
        100
      ],
      [
        1588174959259,
        81.45,
        3,
        72.17,
        44.35,
        100,
        100
      ],
      [
        1588174959026,
        81.45,
        3,
        72.17,
        44.35,
        100,
        100
      ],
      [
        1588174959836,
        74.61,
        3,
        67.36,
        44.35,
        79.49,
        100
      ],
      [
        1588174958302,
        74.61,
        3,
        67.36,
        44.35,
        79.49,
        100
      ],
      [
        1588174958654,
        92.85,
        3,
        90.81,
        78.55,
        100,
        100
      ],
      [
        1588174962752,
        83.67,
        3,
        75.5,
        51.01,
        100,
        100
      ],
      [
        1588174958202,
        81.45,
        3,
        72.17,
        44.35,
        100,
        100
      ],
      [
        1588174959046,
        92.69,
        3,
        89.04,
        78.07,
        100,
        100
      ],
      [
        1588174935626,
        31.29,
        3,
        33.08,
        43.86,
        25,
        25
      ],
      [
        1588174934742,
        99.44,
        3,
        99.37,
        100,
        98.33,
        100
      ],
      [
        1588174822547,
        45.84,
        2,
        50,
        null,
        66.67,
        25
      ],
      [
        null,
        0,
        1,
        0,
        null,
        null,
        0
      ],
      [
        1588174972564,
        53.21,
        3,
        57.7,
        84.64,
        50,
        25
      ],
      [
        1588174972564,
        53.21,
        3,
        57.7,
        84.64,
        50,
        25
      ],
      [
        1588174972600,
        67.38,
        3,
        69.57,
        82.71,
        94.44,
        25
      ],
      [
        1588174925187,
        99.84,
        3,
        99.82,
        99.52,
        100,
        100
      ],
      [
        1588174924904,
        97.29,
        3,
        96.95,
        91.88,
        100,
        100
      ],
      [
        1588174925254,
        48.84,
        3,
        54.95,
        46.52,
        100,
        0
      ],
      [
        1588174924720,
        73.45,
        3,
        76.38,
        70.34,
        100,
        50
      ],
      [
        1588174925118,
        69.75,
        3,
        75.34,
        84.25,
        100,
        25
      ],
      [
        1588174925203,
        92.68,
        3,
        91.76,
        92.85,
        85.19,
        100
      ],
      [
        1588174929538,
        41.04,
        3,
        42.05,
        48.12,
        50,
        25
      ],
      [
        1588174930101,
        50.31,
        3,
        48.57,
        38.12,
        50,
        62.82
      ],
      [
        1588174929177,
        99.84,
        3,
        99.82,
        99.52,
        100,
        100
      ],
      [
        1588174929373,
        97.29,
        3,
        96.95,
        91.88,
        100,
        100
      ],
      [
        1588174930211,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174929858,
        45.81,
        3,
        48.18,
        62.42,
        50,
        25
      ],
      [
        1588174930191,
        78.08,
        3,
        81.59,
        84.25,
        100,
        50
      ],
      [
        1588174929670,
        95.07,
        3,
        94.46,
        85.22,
        100,
        100
      ],
      [
        1588174929664,
        92.85,
        3,
        91.96,
        78.55,
        100,
        100
      ],
      [
        1588174929760,
        50.67,
        3,
        54.43,
        77,
        50,
        25
      ],
      [
        1588174914706,
        95.1,
        3,
        95.59,
        98.55,
        94.44,
        92.31
      ],
      [
        1588174912216,
        65.86,
        3,
        67.22,
        75.36,
        72.22,
        50
      ],
      [
        1588174911516,
        99.52,
        3,
        99.46,
        98.55,
        100,
        100
      ],
      [
        1588174912914,
        49.8,
        3,
        53.31,
        74.4,
        50,
        25
      ],
      [
        1588174912468,
        73.52,
        3,
        73.65,
        74.4,
        83.34,
        62.82
      ],
      [
        1588174914874,
        39.98,
        3,
        44.26,
        69.95,
        50,
        0
      ],
      [
        1588174913838,
        49.52,
        3,
        56.52,
        98.55,
        50,
        0
      ],
      [
        1588174914581,
        54.98,
        3,
        59.98,
        89.95,
        50,
        25
      ],
      [
        1588174912827,
        76.41,
        3,
        81.23,
        91.4,
        100,
        37.82
      ],
      [
        1588174912929,
        75.82,
        3,
        80.73,
        90.92,
        100,
        36.54
      ],
      [
        1588174912057,
        99.84,
        3,
        99.82,
        99.52,
        100,
        100
      ],
      [
        1588174911770,
        99.68,
        3,
        99.64,
        99.03,
        100,
        100
      ],
      [
        1588174912478,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174913649,
        53.85,
        3,
        54.95,
        61.55,
        50,
        50
      ],
      [
        1588174913720,
        50.12,
        3,
        53.73,
        75.36,
        50,
        25
      ],
      [
        1588174914270,
        54.98,
        3,
        59.98,
        89.95,
        50,
        25
      ],
      [
        1588174911293,
        99.15,
        3,
        99.36,
        100,
        100,
        97.44
      ],
      [
        1588174913751,
        58.45,
        3,
        60.87,
        75.36,
        50,
        50
      ],
      [
        1588174914663,
        68.23,
        3,
        71.61,
        91.88,
        50,
        62.82
      ],
      [
        1588174914643,
        50.4,
        3,
        53.19,
        69.95,
        81.25,
        0
      ],
      [
        1588174911415,
        95.07,
        3,
        94.46,
        85.22,
        100,
        100
      ],
      [
        1588174913511,
        73.86,
        3,
        78.37,
        83.77,
        100,
        37.82
      ],
      [
        1588174914611,
        54.98,
        3,
        59.98,
        89.95,
        50,
        25
      ],
      [
        1588174913942,
        39.4,
        3,
        43.52,
        68.21,
        50,
        0
      ],
      [
        1588174912100,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174912771,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174914159,
        77.17,
        3,
        74.32,
        68.7,
        62.82,
        100
      ],
      [
        1588174912389,
        99.36,
        3,
        99.17,
        98.07,
        100,
        100
      ],
      [
        1588174915993,
        56.47,
        3,
        54.74,
        44.4,
        100,
        25
      ],
      [
        1588174911993,
        76.85,
        3,
        80.21,
        84.25,
        96.3,
        50
      ],
      [
        1588174913381,
        72.91,
        3,
        77.3,
        83.29,
        97.62,
        37.82
      ],
      [
        1588174914452,
        99.52,
        3,
        99.46,
        98.55,
        100,
        100
      ],
      [
        1588174913618,
        58.13,
        3,
        60.46,
        74.4,
        50,
        50
      ],
      [
        1588174911077,
        64.8,
        3,
        66.65,
        61.06,
        83.33,
        50
      ],
      [
        1588174915314,
        64.81,
        3,
        69.79,
        89.95,
        79.49,
        25
      ],
      [
        1588174912952,
        97.46,
        3,
        97.14,
        92.37,
        100,
        100
      ],
      [
        1588174914568,
        80.35,
        3,
        82.68,
        96.62,
        94.44,
        50
      ],
      [
        1588174915156,
        76.65,
        3,
        78.55,
        89.95,
        90,
        50
      ],
      [
        1588174892150,
        47.36,
        3,
        50.18,
        67.09,
        50,
        25
      ],
      [
        1588174892886,
        50.3,
        3,
        48.56,
        38.09,
        50,
        62.82
      ],
      [
        1588174892411,
        45.91,
        3,
        44.74,
        37.73,
        50,
        50
      ],
      [
        1588174891576,
        99.65,
        3,
        99.6,
        98.94,
        100,
        100
      ],
      [
        1588174892160,
        99.65,
        3,
        99.6,
        98.94,
        100,
        100
      ],
      [
        1588174893141,
        68.13,
        3,
        66.17,
        54.4,
        100,
        50
      ],
      [
        1588174891702,
        43.92,
        3,
        49.41,
        48.44,
        83.33,
        0
      ],
      [
        1588174892020,
        75,
        3,
        81.25,
        100,
        100,
        25
      ],
      [
        1588174892309,
        64.6,
        3,
        65.2,
        68.79,
        100,
        25
      ],
      [
        1588174892577,
        29.24,
        3,
        30.46,
        37.73,
        50,
        0
      ],
      [
        1588174893211,
        31.03,
        3,
        36.32,
        68.09,
        25,
        0
      ],
      [
        1588174893108,
        82.98,
        3,
        85.26,
        98.94,
        50,
        100
      ],
      [
        1588174891907,
        72.19,
        3,
        78.09,
        91.56,
        100,
        25
      ],
      [
        1588174892735,
        37.58,
        3,
        37.6,
        37.73,
        50,
        25
      ],
      [
        1588174891714,
        92.34,
        3,
        91.39,
        92.27,
        84.76,
        100
      ],
      [
        1588174892677,
        53.29,
        3,
        53.45,
        54.4,
        55.48,
        50
      ],
      [
        1588174906909,
        40.91,
        3,
        41.88,
        47.73,
        50,
        25
      ],
      [
        1588174907121,
        84.09,
        3,
        82.1,
        85.6,
        66.67,
        100
      ],
      [
        1588174907558,
        78.51,
        3,
        79.51,
        85.53,
        100,
        50
      ],
      [
        1588174907000,
        52.2,
        3,
        58.73,
        56.6,
        100,
        0
      ],
      [
        1588174907334,
        69.44,
        3,
        75,
        100,
        83.33,
        25
      ],
      [
        1588174906831,
        50.34,
        3,
        54.01,
        76.03,
        50,
        25
      ],
      [
        1588174907435,
        45.3,
        3,
        47.84,
        48.09,
        62.82,
        25
      ],
      [
        1588174907000,
        35.41,
        3,
        38.39,
        56.24,
        50,
        0
      ],
      [
        1588174907387,
        48.93,
        3,
        51.92,
        55.11,
        66.67,
        25
      ],
      [
        1588174907652,
        29.36,
        3,
        30.61,
        38.09,
        50,
        0
      ],
      [
        1588174978260,
        42.09,
        3,
        41.57,
        38.44,
        50,
        37.82
      ],
      [
        1588174978095,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174977949,
        99.65,
        3,
        99.6,
        98.94,
        100,
        100
      ],
      [
        1588174978151,
        90.81,
        3,
        93.11,
        100,
        100,
        72.44
      ],
      [
        1588174977929,
        62.46,
        3,
        67.15,
        70.07,
        92.32,
        25
      ],
      [
        1588174977591,
        91,
        3,
        89.88,
        92.62,
        80.39,
        100
      ],
      [
        1588174977736,
        64.2,
        3,
        64.68,
        67.59,
        100,
        25
      ],
      [
        1588174972914,
        41.97,
        3,
        41.42,
        38.09,
        50,
        37.82
      ],
      [
        1588174973135,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174973249,
        92.98,
        3,
        92.1,
        78.94,
        100,
        100
      ],
      [
        1588174973353,
        91.49,
        3,
        90.42,
        92.98,
        81.48,
        100
      ],
      [
        1588174973009,
        55.99,
        3,
        56.77,
        61.42,
        56.55,
        50
      ],
      [
        1588174939615,
        55.02,
        3,
        60.03,
        90.07,
        50,
        25
      ],
      [
        1588174939801,
        72.76,
        3,
        76.97,
        98.23,
        80.95,
        39.11
      ],
      [
        1588174939824,
        97.42,
        3,
        97.1,
        92.27,
        100,
        100
      ],
      [
        1588174938861,
        50.42,
        3,
        48.71,
        38.44,
        50,
        62.82
      ],
      [
        1588174940497,
        64.46,
        3,
        69.39,
        98.94,
        94.44,
        0
      ],
      [
        1588174939257,
        45.91,
        3,
        44.74,
        37.73,
        50,
        50
      ],
      [
        1588174941761,
        62.58,
        3,
        64.15,
        54.4,
        83.33,
        50
      ],
      [
        1588174940389,
        73.12,
        3,
        77.54,
        82.7,
        98.85,
        37.82
      ],
      [
        1588174939437,
        99.54,
        3,
        99.48,
        100,
        98.61,
        100
      ],
      [
        1588174939088,
        99.88,
        3,
        99.87,
        99.65,
        100,
        100
      ],
      [
        1588174942127,
        60.35,
        3,
        56.17,
        31.06,
        100,
        50
      ],
      [
        1588174941858,
        40.04,
        3,
        40.76,
        45.11,
        50,
        25
      ],
      [
        1588174939191,
        99.15,
        3,
        99.36,
        100,
        100,
        97.44
      ],
      [
        1588174938567,
        73.94,
        3,
        77.2,
        96.81,
        100,
        25
      ],
      [
        1588174941919,
        31.58,
        3,
        33.46,
        44.75,
        50,
        0
      ],
      [
        1588174940632,
        37.7,
        3,
        37.75,
        38.09,
        50,
        25
      ],
      [
        1588174940908,
        71.97,
        3,
        72.84,
        78.09,
        100,
        37.82
      ],
      [
        1588174941093,
        97.66,
        3,
        97.37,
        92.98,
        100,
        100
      ],
      [
        1588174940249,
        73.39,
        3,
        77.83,
        82.34,
        100,
        37.82
      ],
      [
        1588174818577,
        27.58,
        3,
        24.74,
        7.73,
        50,
        25
      ],
      [
        1588174941643,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174942380,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174941308,
        91.65,
        3,
        90.61,
        84.54,
        90.41,
        100
      ],
      [
        1588174940911,
        82.98,
        3,
        85.26,
        98.94,
        50,
        100
      ],
      [
        1588174941507,
        61.28,
        3,
        64.5,
        83.83,
        100,
        0
      ],
      [
        1588174938682,
        95.32,
        3,
        93.98,
        85.96,
        100,
        100
      ],
      [
        1588174938877,
        74.48,
        3,
        77.54,
        75.67,
        97.78,
        50
      ],
      [
        1588174940236,
        73,
        3,
        77.4,
        82.34,
        98.85,
        37.82
      ],
      [
        1588174939702,
        99.65,
        3,
        99.6,
        98.94,
        100,
        100
      ],
      [
        1588174938373,
        37.81,
        3,
        37.9,
        38.44,
        50,
        25
      ],
      [
        1588174940803,
        37.7,
        3,
        37.75,
        38.09,
        50,
        25
      ],
      [
        1588174940147,
        45.1,
        3,
        47.61,
        59.72,
        50.57,
        25
      ],
      [
        1588174941556,
        61.08,
        3,
        65.59,
        91.56,
        66.67,
        25
      ],
      [
        1588174941169,
        46.34,
        3,
        52.43,
        89.01,
        50,
        0
      ],
      [
        1588174938388,
        92.33,
        3,
        91.37,
        92.98,
        84,
        100
      ],
      [
        1588174940635,
        53.73,
        3,
        53.82,
        54.4,
        56.78,
        50
      ],
      [
        1588174942810,
        72.42,
        3,
        75.26,
        92.27,
        100,
        25
      ],
      [
        1588174893379,
        87.65,
        3,
        88.24,
        91.79,
        100,
        71.16
      ],
      [
        1588174894405,
        61.97,
        3,
        66.11,
        90.92,
        70,
        25
      ],
      [
        1588174896170,
        68,
        3,
        73.38,
        99.52,
        79.49,
        25
      ],
      [
        1588174894166,
        99.68,
        3,
        99.64,
        99.03,
        100,
        100
      ],
      [
        1588174893614,
        70.04,
        3,
        72.19,
        85.12,
        100,
        25
      ],
      [
        1588174896706,
        57.32,
        3,
        61.16,
        84.15,
        50,
        37.82
      ],
      [
        1588174897762,
        38.21,
        3,
        41.99,
        64.64,
        50,
        0
      ],
      [
        1588174896971,
        61.55,
        3,
        64.85,
        84.64,
        100,
        0
      ],
      [
        1588174897732,
        80.99,
        3,
        81.51,
        84.64,
        100,
        58.34
      ],
      [
        1588174895742,
        75.17,
        3,
        79.84,
        91.4,
        96.3,
        37.82
      ],
      [
        1588174894884,
        74.75,
        3,
        79.52,
        91.4,
        96.3,
        36.54
      ],
      [
        1588174897064,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174894456,
        99.68,
        3,
        99.64,
        99.03,
        100,
        100
      ],
      [
        1588174895306,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174896288,
        80.92,
        3,
        82.61,
        92.75,
        100,
        50
      ],
      [
        1588174895454,
        66.87,
        3,
        68.11,
        75.6,
        100,
        25
      ],
      [
        1588174895568,
        72.26,
        3,
        75.05,
        91.79,
        100,
        25
      ],
      [
        1588174896475,
        63.77,
        3,
        71.74,
        91.3,
        100,
        0
      ],
      [
        1588174898065,
        53.21,
        3,
        57.7,
        84.64,
        50,
        25
      ],
      [
        1588174896546,
        90.39,
        3,
        92.79,
        100,
        100,
        71.16
      ],
      [
        1588174896740,
        72.29,
        3,
        75.09,
        91.88,
        100,
        25
      ],
      [
        1588174895380,
        58.7,
        3,
        61.18,
        76.09,
        100,
        0
      ],
      [
        1588174894842,
        69.88,
        3,
        71.99,
        84.64,
        100,
        25
      ],
      [
        1588174895816,
        83.38,
        3,
        84.58,
        91.79,
        100,
        58.34
      ],
      [
        1588174894373,
        99.84,
        3,
        99.82,
        99.52,
        100,
        100
      ],
      [
        1588174893734,
        74.85,
        3,
        79.48,
        90.43,
        96.3,
        37.82
      ],
      [
        1588174897289,
        39.5,
        3,
        43.64,
        68.5,
        50,
        0
      ],
      [
        1588174897698,
        72.58,
        3,
        75.46,
        92.75,
        100,
        25
      ],
      [
        1588174896374,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174895182,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174897042,
        83.01,
        3,
        85.3,
        99.03,
        50,
        100
      ],
      [
        1588174897289,
        45.33,
        3,
        48.64,
        68.5,
        67.5,
        0
      ],
      [
        1588174894158,
        72.13,
        3,
        78.03,
        91.4,
        100,
        25
      ],
      [
        1588174893774,
        70.9,
        3,
        76.64,
        91.4,
        96.3,
        25
      ],
      [
        1588174894728,
        97.62,
        3,
        97.32,
        92.85,
        100,
        100
      ],
      [
        1588174895006,
        72.75,
        3,
        75.67,
        93.24,
        100,
        25
      ],
      [
        1588174894887,
        54.55,
        3,
        58.24,
        77.97,
        60.67,
        25
      ],
      [
        1588174897289,
        45.85,
        3,
        49.09,
        68.5,
        69.05,
        0
      ],
      [
        1588174894323,
        93.22,
        3,
        92.38,
        92.37,
        87.3,
        100
      ],
      [
        1588174897225,
        44,
        3,
        44.59,
        48.12,
        58.89,
        25
      ],
      [
        1588174896048,
        69.72,
        3,
        71.78,
        84.15,
        100,
        25
      ],
      [
        1588174942953,
        92.21,
        3,
        89.98,
        76.62,
        100,
        100
      ],
      [
        1588174788522,
        100,
        1,
        100,
        null,
        null,
        100
      ],
      [
        1588174935534,
        35.22,
        2,
        42.26,
        70.43,
        null,
        0
      ],
      [
        1588174955810,
        44.1,
        3,
        47.93,
        70.92,
        61.37,
        0
      ],
      [
        1588174973577,
        93.46,
        3,
        94.18,
        98.55,
        81.82,
        100
      ],
      [
        1588174955633,
        95.43,
        2,
        96.05,
        98.55,
        null,
        92.31
      ],
      [
        1588174955515,
        38.82,
        3,
        40.54,
        24.78,
        66.67,
        25
      ],
      [
        1588174788522,
        8.33,
        3,
        6.25,
        0,
        0,
        25
      ],
      [
        1588174788522,
        15.84,
        2,
        14,
        6.67,
        null,
        25
      ],
      [
        1588174955510,
        34.45,
        3,
        35.52,
        41.93,
        61.43,
        0
      ],
      [
        1588174931361,
        20.24,
        2,
        24.29,
        40.48,
        null,
        0
      ],
      [
        1588174963432,
        70.82,
        3,
        73.75,
        91.3,
        50,
        71.16
      ],
      [
        1588174964117,
        63.8,
        3,
        67.74,
        91.4,
        75,
        25
      ],
      [
        1588174965246,
        97.62,
        3,
        97.32,
        92.85,
        100,
        100
      ],
      [
        1588174964120,
        67.82,
        3,
        69.34,
        78.45,
        100,
        25
      ],
      [
        1588174964120,
        67.82,
        3,
        69.34,
        78.45,
        100,
        25
      ],
      [
        1588174964658,
        55.76,
        3,
        60.97,
        92.27,
        50,
        25
      ],
      [
        1588174965918,
        44.88,
        3,
        50.56,
        84.64,
        50,
        0
      ],
      [
        1588174963433,
        52.92,
        3,
        57.33,
        83.77,
        50,
        25
      ],
      [
        1588174968913,
        48.61,
        3,
        51.78,
        70.82,
        50,
        25
      ],
      [
        1588174967506,
        60.12,
        3,
        64.51,
        83.77,
        71.6,
        25
      ],
      [
        1588174968069,
        76.08,
        3,
        80.87,
        90.43,
        100,
        37.82
      ],
      [
        1588174968482,
        44.72,
        3,
        50.35,
        84.15,
        50,
        0
      ],
      [
        1588174963282,
        62.37,
        3,
        67.04,
        77.49,
        84.62,
        25
      ],
      [
        1588174968045,
        78.63,
        3,
        83.73,
        98.07,
        100,
        37.82
      ],
      [
        1588174965678,
        99.04,
        3,
        99.24,
        100,
        99.68,
        97.44
      ],
      [
        1588174963262,
        97.62,
        3,
        97.32,
        92.85,
        100,
        100
      ],
      [
        1588174963262,
        97.62,
        3,
        97.32,
        92.85,
        100,
        100
      ],
      [
        1588174963262,
        97.62,
        3,
        97.32,
        92.85,
        100,
        100
      ],
      [
        1588174965012,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174966213,
        61.38,
        3,
        64.64,
        84.15,
        50,
        50
      ],
      [
        1588174967707,
        63.55,
        3,
        68.36,
        83.29,
        82.35,
        25
      ],
      [
        1588174965011,
        53.53,
        3,
        58.11,
        85.6,
        50,
        25
      ],
      [
        1588174966680,
        53.05,
        3,
        57.49,
        84.15,
        50,
        25
      ],
      [
        1588174968882,
        48.61,
        3,
        51.78,
        70.82,
        50,
        25
      ],
      [
        1588174964886,
        90.81,
        3,
        93.11,
        100,
        100,
        72.44
      ],
      [
        1588174962743,
        71.81,
        3,
        74.47,
        90.43,
        100,
        25
      ],
      [
        1588174965851,
        42.5,
        3,
        47.5,
        77.49,
        50,
        0
      ],
      [
        1588174964201,
        62.37,
        3,
        67.04,
        77.49,
        84.62,
        25
      ],
      [
        1588174968682,
        42.45,
        3,
        47.76,
        84.15,
        43.21,
        0
      ],
      [
        1588174963799,
        64.59,
        3,
        69.54,
        84.15,
        84.62,
        25
      ],
      [
        1588174966370,
        50.83,
        3,
        54.64,
        77.49,
        50,
        25
      ],
      [
        1588174965037,
        55.1,
        3,
        58.3,
        77.49,
        50,
        37.82
      ],
      [
        1588174966761,
        48.32,
        3,
        51.41,
        69.95,
        75,
        0
      ],
      [
        1588174967104,
        40.27,
        3,
        44.64,
        70.82,
        50,
        0
      ],
      [
        1588174964598,
        94,
        3,
        93.25,
        85.7,
        96.3,
        100
      ],
      [
        1588174968233,
        76.08,
        3,
        80.87,
        90.43,
        100,
        37.82
      ],
      [
        1588174968560,
        42.66,
        3,
        47.7,
        77.97,
        50,
        0
      ],
      [
        1588174968400,
        53.05,
        3,
        57.49,
        84.15,
        50,
        25
      ],
      [
        1588174967356,
        53.21,
        3,
        57.7,
        84.64,
        50,
        25
      ],
      [
        1588174966826,
        53.05,
        3,
        57.49,
        84.15,
        50,
        25
      ],
      [
        1588174964119,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174967463,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174964579,
        92.95,
        3,
        92.07,
        84.64,
        94.21,
        100
      ],
      [
        1588174964678,
        95.76,
        3,
        95.35,
        92.85,
        94.44,
        100
      ],
      [
        1588174966071,
        80.79,
        3,
        82.44,
        92.37,
        50,
        100
      ],
      [
        1588174967748,
        71.81,
        3,
        77.66,
        90.43,
        100,
        25
      ],
      [
        1588174966982,
        61.94,
        3,
        61.78,
        60.82,
        100,
        25
      ],
      [
        1588174966982,
        61.94,
        3,
        61.78,
        60.82,
        100,
        25
      ],
      [
        1588174963254,
        71,
        3,
        76.75,
        92.37,
        95.62,
        25
      ],
      [
        1588174968248,
        78.47,
        3,
        83.55,
        97.58,
        100,
        37.82
      ],
      [
        1588174965433,
        97.46,
        3,
        97.14,
        92.37,
        100,
        100
      ],
      [
        1588174966205,
        55.27,
        3,
        60.35,
        90.82,
        50,
        25
      ],
      [
        1588174963797,
        53.21,
        3,
        57.7,
        84.64,
        50,
        25
      ],
      [
        1588174964408,
        64.32,
        3,
        69.24,
        84.64,
        83.33,
        25
      ],
      [
        1588174967096,
        62.45,
        3,
        67.13,
        83.77,
        78.57,
        25
      ],
      [
        1588174963972,
        87.65,
        3,
        86.11,
        100,
        62.96,
        100
      ],
      [
        1588174966505,
        54.94,
        3,
        59.06,
        83.77,
        56.06,
        25
      ],
      [
        1588174965724,
        69.72,
        3,
        71.78,
        84.15,
        100,
        25
      ],
      [
        1588174965734,
        71.97,
        3,
        74.68,
        90.92,
        100,
        25
      ],
      [
        1588174954530,
        90.06,
        3,
        91.34,
        99.03,
        100,
        71.16
      ],
      [
        1588174953449,
        63.48,
        3,
        67.33,
        90.43,
        75,
        25
      ],
      [
        1588174954040,
        97.62,
        3,
        97.32,
        92.85,
        100,
        100
      ],
      [
        1588174954168,
        65.43,
        3,
        66.27,
        71.3,
        100,
        25
      ],
      [
        1588174954168,
        65.27,
        3,
        66.07,
        70.82,
        100,
        25
      ],
      [
        1588174953644,
        51.77,
        3,
        54.02,
        67.49,
        50,
        37.82
      ],
      [
        1588174954647,
        38.37,
        3,
        42.19,
        65.12,
        50,
        0
      ],
      [
        1588174954736,
        66.34,
        3,
        71.01,
        99.03,
        100,
        0
      ],
      [
        1588174954798,
        83.21,
        3,
        84.37,
        91.3,
        100,
        58.34
      ],
      [
        1588174935873,
        92.42,
        3,
        91.64,
        78.55,
        100,
        98.72
      ],
      [
        1588174952518,
        99.41,
        3,
        99.5,
        99.52,
        100,
        98.72
      ],
      [
        1588174953507,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174954419,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174953134,
        47.17,
        3,
        49.94,
        66.52,
        50,
        25
      ],
      [
        1588174954373,
        64,
        3,
        64.43,
        67,
        100,
        25
      ],
      [
        1588174952504,
        55.35,
        3,
        62.27,
        66.04,
        100,
        0
      ],
      [
        1588174935958,
        83.3,
        3,
        84.82,
        78.74,
        100,
        71.16
      ],
      [
        1588174952755,
        90.23,
        3,
        92.61,
        99.52,
        100,
        71.16
      ],
      [
        1588174952257,
        53.08,
        3,
        57.54,
        84.25,
        50,
        25
      ],
      [
        1588174955307,
        39.65,
        3,
        43.83,
        68.94,
        50,
        0
      ],
      [
        1588174955033,
        83.54,
        3,
        84.78,
        92.27,
        100,
        58.34
      ],
      [
        1588174953990,
        39.98,
        3,
        44.26,
        69.95,
        50,
        0
      ],
      [
        1588174953850,
        97.62,
        3,
        97.32,
        92.85,
        100,
        100
      ],
      [
        1588174955152,
        72.26,
        3,
        75.05,
        91.79,
        100,
        25
      ],
      [
        1588174953468,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174952206,
        80.63,
        3,
        82.23,
        91.88,
        50,
        100
      ],
      [
        1588174972691,
        80.63,
        3,
        82.23,
        91.88,
        50,
        100
      ],
      [
        1588174955393,
        97.46,
        3,
        96.73,
        92.37,
        100,
        100
      ],
      [
        1588174952827,
        70.07,
        3,
        75.71,
        85.22,
        100,
        25
      ],
      [
        1588174972815,
        71.28,
        3,
        77.06,
        90.92,
        97.92,
        25
      ],
      [
        1588174953919,
        97.62,
        3,
        97.32,
        92.85,
        100,
        100
      ],
      [
        1588174952584,
        97.62,
        3,
        97.32,
        92.85,
        100,
        100
      ],
      [
        1588174953129,
        59.24,
        3,
        58.3,
        52.71,
        100,
        25
      ],
      [
        1588174955110,
        74.36,
        3,
        77.74,
        98.07,
        100,
        25
      ],
      [
        1588174935554,
        92.85,
        3,
        91.96,
        78.55,
        100,
        100
      ],
      [
        1588174935714,
        92.85,
        3,
        91.96,
        78.55,
        100,
        100
      ],
      [
        1588174935752,
        92.85,
        3,
        91.96,
        78.55,
        100,
        100
      ],
      [
        1588174936203,
        84.52,
        3,
        85.71,
        78.55,
        100,
        75
      ],
      [
        1588174901407,
        89.26,
        3,
        90.31,
        96.62,
        100,
        71.16
      ],
      [
        1588174901644,
        65.86,
        3,
        70.39,
        97.58,
        75,
        25
      ],
      [
        1588174901523,
        64.81,
        3,
        69.79,
        89.95,
        79.49,
        25
      ],
      [
        1588174899081,
        99.52,
        3,
        99.46,
        98.55,
        100,
        100
      ],
      [
        1588174904243,
        69.43,
        3,
        71.41,
        83.29,
        100,
        25
      ],
      [
        1588174902236,
        67.52,
        3,
        72.84,
        79.95,
        97.62,
        25
      ],
      [
        1588174900829,
        53.54,
        3,
        56.29,
        72.8,
        50,
        37.82
      ],
      [
        1588174902047,
        41.2,
        3,
        42.26,
        48.6,
        75,
        0
      ],
      [
        1588174904204,
        50.54,
        3,
        54.27,
        76.62,
        75,
        0
      ],
      [
        1588174903437,
        55.18,
        3,
        53.08,
        40.53,
        100,
        25
      ],
      [
        1588174902938,
        55.14,
        3,
        60.18,
        90.43,
        50,
        25
      ],
      [
        1588174900053,
        54.31,
        3,
        61.09,
        66.62,
        96.3,
        0
      ],
      [
        1588174900143,
        66.89,
        3,
        72.12,
        96.62,
        79.04,
        25
      ],
      [
        1588174901034,
        78.31,
        3,
        83.37,
        97.1,
        100,
        37.82
      ],
      [
        1588174903342,
        61.1,
        3,
        64.27,
        83.29,
        100,
        0
      ],
      [
        1588174899583,
        73.99,
        3,
        78.52,
        89.95,
        94.21,
        37.82
      ],
      [
        1588174898983,
        91.95,
        3,
        90.94,
        98.07,
        77.78,
        100
      ],
      [
        1588174899902,
        91.18,
        3,
        93.21,
        98.55,
        100,
        75
      ],
      [
        1588174899458,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174900303,
        82.21,
        3,
        84.27,
        96.62,
        100,
        50
      ],
      [
        1588174900376,
        75.38,
        3,
        78.55,
        97.1,
        79.04,
        50
      ],
      [
        1588174898821,
        41.76,
        3,
        46.98,
        25.27,
        100,
        0
      ],
      [
        1588174904836,
        66.18,
        3,
        74.46,
        98.55,
        100,
        0
      ],
      [
        1588174902344,
        48.98,
        3,
        48.45,
        45.27,
        76.66,
        25
      ],
      [
        1588174898560,
        96.76,
        3,
        96.68,
        92.85,
        100,
        97.44
      ],
      [
        1588174903645,
        54.98,
        3,
        59.98,
        89.95,
        50,
        25
      ],
      [
        1588174904416,
        57.76,
        3,
        59.98,
        73.29,
        100,
        0
      ],
      [
        1588174903003,
        60.31,
        3,
        67.84,
        80.92,
        100,
        0
      ],
      [
        1588174901380,
        78.31,
        3,
        80.99,
        97.1,
        100,
        37.82
      ],
      [
        1588174902712,
        49.4,
        3,
        52.41,
        70.43,
        77.78,
        0
      ],
      [
        1588174901929,
        42.31,
        3,
        43.68,
        51.93,
        50,
        25
      ],
      [
        1588174899252,
        97.9,
        3,
        97.64,
        98.55,
        95.15,
        100
      ],
      [
        1588174900830,
        78.15,
        3,
        83.19,
        96.62,
        100,
        37.82
      ],
      [
        1588174901855,
        73.87,
        3,
        77.12,
        96.62,
        100,
        25
      ],
      [
        1588174903575,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174899151,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174901764,
        92.27,
        3,
        91.3,
        92.37,
        84.44,
        100
      ],
      [
        1588174903834,
        82.85,
        3,
        85.09,
        98.55,
        50,
        100
      ],
      [
        1588174900200,
        75.42,
        3,
        78.59,
        79.95,
        96.3,
        50
      ],
      [
        1588174902359,
        99.52,
        3,
        99.38,
        98.55,
        100,
        100
      ],
      [
        1588174903124,
        75.01,
        3,
        78.14,
        96.62,
        78.41,
        50
      ],
      [
        1588174900157,
        73.2,
        3,
        76.1,
        73.29,
        96.3,
        50
      ],
      [
        1588174900587,
        71.97,
        3,
        77.84,
        90.92,
        100,
        25
      ],
      [
        1588174901141,
        78.31,
        3,
        83.37,
        97.1,
        100,
        37.82
      ],
      [
        1588174900712,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174898672,
        57.05,
        3,
        62.63,
        96.14,
        50,
        25
      ],
      [
        1588174903822,
        76.65,
        3,
        77.12,
        79.95,
        100,
        50
      ],
      [
        1588174899722,
        61.62,
        3,
        66.19,
        96.62,
        63.23,
        25
      ],
      [
        1588174904064,
        62.19,
        3,
        66.83,
        98.07,
        63.49,
        25
      ],
      [
        1588174904549,
        99.68,
        3,
        99.64,
        99.03,
        100,
        100
      ],
      [
        1588174904544,
        45.18,
        3,
        46.01,
        51.01,
        59.52,
        25
      ],
      [
        1588174902938,
        54.98,
        3,
        59.98,
        89.95,
        50,
        25
      ],
      [
        1588174902463,
        33.82,
        3,
        36.34,
        51.45,
        50,
        0
      ],
      [
        1588174903083,
        71.65,
        3,
        74.26,
        89.95,
        100,
        25
      ],
      [
        1588174972428,
        90.39,
        3,
        91.76,
        100,
        100,
        71.16
      ],
      [
        1588174971707,
        61.25,
        3,
        64.47,
        83.76,
        75,
        25
      ],
      [
        1588174969040,
        58.33,
        3,
        64.29,
        100,
        50,
        25
      ],
      [
        1588174970621,
        44.31,
        3,
        44.42,
        45.11,
        50,
        37.82
      ],
      [
        1588174971497,
        62.12,
        3,
        67.53,
        100,
        86.37,
        0
      ],
      [
        1588174969373,
        52.28,
        3,
        50.25,
        38.09,
        93.75,
        25
      ],
      [
        1588174970070,
        70.93,
        3,
        75.06,
        74.96,
        100,
        37.82
      ],
      [
        1588174969528,
        99.76,
        3,
        99.73,
        99.65,
        99.63,
        100
      ],
      [
        1588174970437,
        99.65,
        3,
        99.6,
        98.94,
        100,
        100
      ],
      [
        1588174971940,
        61.18,
        3,
        58.83,
        44.75,
        87.5,
        51.28
      ],
      [
        1588174972128,
        35.47,
        3,
        34.89,
        31.42,
        50,
        25
      ],
      [
        1588174970476,
        89.84,
        3,
        92.34,
        99.65,
        100,
        69.87
      ],
      [
        1588174969140,
        72.07,
        3,
        74.8,
        91.21,
        100,
        25
      ],
      [
        1588174972071,
        31.58,
        3,
        33.46,
        44.75,
        50,
        0
      ],
      [
        1588174970994,
        37.81,
        3,
        37.9,
        38.44,
        50,
        25
      ],
      [
        1588174971885,
        76.79,
        3,
        79.04,
        92.55,
        100,
        37.82
      ],
      [
        1588174971875,
        37.33,
        3,
        40.85,
        61.99,
        50,
        0
      ],
      [
        1588174969799,
        70.93,
        3,
        75.06,
        74.96,
        100,
        37.82
      ],
      [
        1588174969618,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174971221,
        95.69,
        3,
        95.25,
        92.62,
        94.44,
        100
      ],
      [
        1588174970748,
        97.42,
        3,
        96.69,
        92.27,
        100,
        100
      ],
      [
        1588174970255,
        65.86,
        3,
        70.96,
        76.74,
        95.83,
        25
      ],
      [
        1588174969888,
        70.81,
        3,
        74.93,
        74.61,
        100,
        37.82
      ],
      [
        1588174969272,
        37.7,
        3,
        37.75,
        38.09,
        50,
        25
      ],
      [
        1588174970989,
        37.58,
        3,
        37.6,
        37.73,
        50,
        25
      ],
      [
        1588174969752,
        40.91,
        3,
        42.9,
        54.4,
        43.33,
        25
      ],
      [
        1588174972398,
        65.59,
        3,
        70.66,
        92.27,
        79.49,
        25
      ],
      [
        1588174970366,
        93.16,
        3,
        92.31,
        92.27,
        87.21,
        100
      ],
      [
        1588174971594,
        42.71,
        3,
        43.43,
        47.73,
        55.41,
        25
      ],
      [
        1588174971501,
        64.91,
        3,
        65.59,
        69.72,
        100,
        25
      ],
      [
        1588174945918,
        93.79,
        3,
        92.57,
        85.22,
        100,
        96.16
      ],
      [
        1588174947442,
        59.93,
        3,
        59.19,
        54.78,
        75,
        50
      ],
      [
        1588174946710,
        97.46,
        3,
        97.14,
        92.37,
        100,
        100
      ],
      [
        1588174946176,
        48.25,
        3,
        45.92,
        31.93,
        50,
        62.82
      ],
      [
        1588174947116,
        57.1,
        3,
        59.13,
        71.3,
        100,
        0
      ],
      [
        1588174946487,
        99.68,
        3,
        99.58,
        99.03,
        100,
        100
      ],
      [
        1588174946340,
        99.68,
        3,
        99.64,
        99.03,
        100,
        100
      ],
      [
        1588174946686,
        40.41,
        3,
        41.24,
        46.23,
        50,
        25
      ],
      [
        1588174947547,
        45.64,
        3,
        47.97,
        61.93,
        50,
        25
      ],
      [
        1588174946873,
        99.15,
        3,
        99.36,
        100,
        100,
        97.44
      ],
      [
        1588174947168,
        85.36,
        3,
        87.31,
        99.03,
        100,
        57.05
      ],
      [
        1588174834489,
        27.71,
        3,
        24.91,
        8.12,
        50,
        25
      ],
      [
        1588174946226,
        80.63,
        3,
        82.23,
        91.88,
        50,
        100
      ],
      [
        1588174945996,
        80.47,
        3,
        84.28,
        91.4,
        100,
        50
      ],
      [
        1588174947286,
        92.85,
        3,
        91.96,
        78.55,
        100,
        100
      ],
      [
        1588174947366,
        49.3,
        3,
        48.18,
        41.45,
        56.45,
        50
      ],
      [
        1588174947366,
        49.3,
        3,
        48.18,
        41.45,
        56.45,
        50
      ],
      [
        1588174915059,
        87.68,
        3,
        88.28,
        91.88,
        100,
        71.16
      ],
      [
        1588174915660,
        66.34,
        3,
        71.01,
        99.03,
        75,
        25
      ],
      [
        1588174918126,
        92.85,
        3,
        91.96,
        78.55,
        100,
        100
      ],
      [
        1588174919356,
        67.69,
        3,
        69.17,
        78.07,
        100,
        25
      ],
      [
        1588174919356,
        67.85,
        3,
        69.38,
        78.55,
        100,
        25
      ],
      [
        1588174918725,
        49.76,
        3,
        51.43,
        61.45,
        50,
        37.82
      ],
      [
        1588174920762,
        59.03,
        3,
        61.61,
        77.1,
        100,
        0
      ],
      [
        1588174918783,
        55.07,
        3,
        61.28,
        98.55,
        66.66,
        0
      ],
      [
        1588174919853,
        45.48,
        3,
        47.76,
        61.45,
        50,
        25
      ],
      [
        1588174920457,
        70.23,
        3,
        72.44,
        85.7,
        100,
        25
      ],
      [
        1588174917227,
        65.14,
        3,
        68.55,
        90.92,
        66.67,
        37.82
      ],
      [
        1588174917622,
        56.2,
        3,
        57.97,
        68.6,
        100,
        0
      ],
      [
        1588174915722,
        65.14,
        3,
        68.55,
        90.92,
        66.67,
        37.82
      ],
      [
        1588174915487,
        96.9,
        3,
        96.51,
        99.03,
        91.67,
        100
      ],
      [
        1588174914909,
        99.68,
        3,
        99.64,
        99.03,
        100,
        100
      ],
      [
        1588174915883,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174918439,
        82.53,
        3,
        84.68,
        97.58,
        100,
        50
      ],
      [
        1588174915294,
        72.34,
        3,
        75.95,
        97.58,
        94.44,
        25
      ],
      [
        1588174920002,
        64.53,
        3,
        65.11,
        68.6,
        100,
        25
      ],
      [
        1588174920317,
        55.14,
        3,
        60.18,
        90.43,
        50,
        25
      ],
      [
        1588174916162,
        90.81,
        3,
        93.11,
        100,
        100,
        72.44
      ],
      [
        1588174915622,
        74.19,
        3,
        77.53,
        97.58,
        100,
        25
      ],
      [
        1588174918535,
        63.64,
        3,
        67.54,
        90.92,
        100,
        0
      ],
      [
        1588174917756,
        51.76,
        3,
        58.23,
        55.27,
        100,
        0
      ],
      [
        1588174919554,
        64.53,
        3,
        65.11,
        68.6,
        100,
        25
      ],
      [
        1588174916163,
        78.79,
        3,
        81.61,
        98.55,
        100,
        37.82
      ],
      [
        1588174919347,
        40.14,
        3,
        44.47,
        70.43,
        50,
        0
      ],
      [
        1588174917929,
        71.81,
        3,
        74.47,
        90.43,
        100,
        25
      ],
      [
        1588174916166,
        97.46,
        3,
        97.14,
        92.37,
        100,
        100
      ],
      [
        1588174917335,
        65.14,
        3,
        68.55,
        90.92,
        66.67,
        37.82
      ],
      [
        1588174917940,
        39.53,
        3,
        43.69,
        68.6,
        25,
        25
      ],
      [
        1588174920269,
        53.42,
        3,
        55.59,
        68.6,
        66.66,
        25
      ],
      [
        1588174919851,
        74.19,
        3,
        77.53,
        97.58,
        100,
        25
      ],
      [
        1588174915397,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174916293,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174916630,
        92.52,
        3,
        91.58,
        98.07,
        79.49,
        100
      ],
      [
        1588174916620,
        80.79,
        3,
        82.44,
        92.37,
        50,
        100
      ],
      [
        1588174918318,
        99.68,
        3,
        99.58,
        99.03,
        100,
        100
      ],
      [
        1588174919140,
        54.37,
        3,
        52.05,
        38.12,
        100,
        25
      ],
      [
        1588174919140,
        54.37,
        3,
        52.05,
        38.12,
        100,
        25
      ],
      [
        1588174916758,
        71.16,
        3,
        76.93,
        90.43,
        98.04,
        25
      ],
      [
        1588174916908,
        65.14,
        3,
        68.55,
        90.92,
        66.67,
        37.82
      ],
      [
        1588174919090,
        75.09,
        3,
        71.97,
        41.93,
        83.33,
        100
      ],
      [
        1588174919362,
        67.78,
        3,
        72.04,
        97.58,
        80.77,
        25
      ],
      [
        1588174917034,
        74.19,
        3,
        77.53,
        97.58,
        100,
        25
      ],
      [
        1588174917451,
        61.39,
        3,
        65.94,
        90.43,
        68.73,
        25
      ],
      [
        1588174920038,
        62.72,
        3,
        67.44,
        83.67,
        79.49,
        25
      ],
      [
        1588174917097,
        97.29,
        3,
        96.95,
        91.88,
        100,
        100
      ],
      [
        1588174917477,
        56.95,
        3,
        61.73,
        90.43,
        55.41,
        25
      ],
      [
        1588174917477,
        56.95,
        3,
        61.73,
        90.43,
        55.41,
        25
      ],
      [
        1588174920525,
        85.47,
        3,
        87.27,
        98.07,
        100,
        58.34
      ],
      [
        1588174918257,
        74.19,
        3,
        77.53,
        97.58,
        100,
        25
      ],
      [
        1588174956823,
        59.86,
        3,
        61.09,
        62.9,
        66.67,
        50
      ],
      [
        1588174956694,
        86.74,
        3,
        88.21,
        85.22,
        100,
        75
      ],
      [
        1588174957037,
        92.85,
        3,
        91.96,
        78.55,
        100,
        100
      ],
      [
        1588174957552,
        55.09,
        3,
        55.73,
        48.6,
        66.67,
        50
      ],
      [
        1588174957334,
        47.87,
        3,
        50.83,
        68.6,
        50,
        25
      ],
      [
        1588174956945,
        57.15,
        3,
        58.04,
        54.78,
        66.67,
        50
      ],
      [
        1588174957185,
        40.99,
        3,
        41.99,
        47.97,
        50,
        25
      ],
      [
        1588174957558,
        63.07,
        3,
        67.83,
        84.73,
        79.49,
        25
      ],
      [
        1588174931226,
        73.85,
        3,
        74.31,
        77.1,
        94.44,
        50
      ],
      [
        1588174945638,
        63.61,
        3,
        67.49,
        90.82,
        75,
        25
      ],
      [
        1588174943975,
        99.52,
        3,
        99.46,
        98.55,
        100,
        100
      ],
      [
        1588174943621,
        42.3,
        3,
        41.84,
        39.08,
        50,
        37.82
      ],
      [
        1588174944269,
        57.13,
        3,
        59.17,
        71.4,
        100,
        0
      ],
      [
        1588174944104,
        63.8,
        3,
        67.74,
        91.4,
        100,
        0
      ],
      [
        1588174944647,
        64.02,
        3,
        67.3,
        70.92,
        83.33,
        37.82
      ],
      [
        1588174944507,
        39.24,
        3,
        43.31,
        67.73,
        50,
        0
      ],
      [
        1588174812928,
        33.26,
        3,
        34.3,
        8.12,
        66.67,
        25
      ],
      [
        1588174943342,
        98.44,
        3,
        98.25,
        99.03,
        96.3,
        100
      ],
      [
        1588174945249,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174944041,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174945546,
        80.14,
        3,
        81.61,
        90.43,
        100,
        50
      ],
      [
        1588174945313,
        90.81,
        3,
        93.11,
        100,
        100,
        72.44
      ],
      [
        1588174945838,
        57.53,
        3,
        63.25,
        97.58,
        50,
        25
      ],
      [
        1588174944360,
        76.25,
        3,
        78.34,
        90.92,
        100,
        37.82
      ],
      [
        1588174944556,
        72.13,
        3,
        74.89,
        91.4,
        100,
        25
      ],
      [
        1588174943303,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174943476,
        73.98,
        3,
        70.73,
        55.27,
        66.67,
        100
      ],
      [
        1588174943833,
        80.63,
        3,
        82.23,
        91.88,
        50,
        100
      ],
      [
        1588174944749,
        69.31,
        3,
        74.84,
        84.25,
        98.67,
        25
      ],
      [
        1588174945332,
        93.01,
        3,
        92.14,
        79.03,
        100,
        100
      ],
      [
        1588174945631,
        57.37,
        3,
        63.04,
        97.1,
        50,
        25
      ],
      [
        1588174944921,
        50.75,
        3,
        53.97,
        64.93,
        62.32,
        25
      ],
      [
        1588174945089,
        92.36,
        3,
        91.4,
        91.88,
        85.19,
        100
      ],
      [
        1588174943477,
        52.06,
        3,
        54.89,
        71.88,
        59.3,
        25
      ],
      [
        1588174942753,
        39.62,
        3,
        40.23,
        43.86,
        50,
        25
      ],
      [
        1588174924515,
        33.33,
        3,
        28.57,
        0,
        100,
        0
      ],
      [
        1588174897871,
        64.53,
        3,
        65.11,
        68.6,
        75,
        50
      ],
      [
        1588174898198,
        37.71,
        3,
        37.77,
        38.12,
        50,
        25
      ],
      [
        1588174836554,
        35.56,
        3,
        35,
        31.67,
        50,
        25
      ],
      [
        1588174898099,
        87.93,
        3,
        86.42,
        85.22,
        78.57,
        100
      ],
      [
        1588174898123,
        49.37,
        3,
        48.24,
        41.45,
        56.66,
        50
      ],
      [
        1588174928107,
        74.36,
        3,
        77.74,
        98.07,
        75,
        50
      ],
      [
        1588174928342,
        92.85,
        3,
        91.96,
        78.55,
        100,
        100
      ],
      [
        1588174928014,
        61.55,
        3,
        64.85,
        84.64,
        100,
        0
      ],
      [
        1588174926609,
        50.47,
        3,
        48.78,
        38.6,
        50,
        62.82
      ],
      [
        1588174928608,
        56.94,
        3,
        58.92,
        70.82,
        100,
        0
      ],
      [
        1588174928484,
        61.55,
        3,
        64.85,
        84.64,
        100,
        0
      ],
      [
        1588174821625,
        41.6,
        3,
        40.55,
        8.12,
        66.67,
        50
      ],
      [
        1588174926816,
        70.83,
        3,
        74.95,
        78.36,
        96.3,
        37.82
      ],
      [
        1588174926754,
        70.99,
        3,
        75.13,
        78.84,
        96.3,
        37.82
      ],
      [
        1588174927678,
        97.46,
        3,
        96.73,
        92.37,
        100,
        100
      ],
      [
        1588174926660,
        99.68,
        3,
        99.64,
        99.03,
        100,
        100
      ],
      [
        1588174821079,
        41.6,
        3,
        40.55,
        8.12,
        66.67,
        50
      ],
      [
        1588174928864,
        99.15,
        3,
        99.36,
        100,
        100,
        97.44
      ],
      [
        1588174927801,
        85.1,
        3,
        84.97,
        84.15,
        100,
        71.16
      ],
      [
        1588174927347,
        70.99,
        3,
        75.13,
        78.84,
        96.3,
        37.82
      ],
      [
        1588174928393,
        69.88,
        3,
        71.99,
        84.64,
        100,
        25
      ],
      [
        1588174928840,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174927135,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174929132,
        80.79,
        3,
        82.44,
        92.37,
        50,
        100
      ],
      [
        1588174927181,
        80.47,
        3,
        84.28,
        91.4,
        100,
        50
      ],
      [
        1588174926998,
        73.21,
        3,
        77.63,
        85.51,
        96.3,
        37.82
      ],
      [
        1588174927434,
        78.53,
        3,
        82.1,
        85.6,
        100,
        50
      ],
      [
        1588174928142,
        69.53,
        3,
        65.72,
        25.27,
        83.33,
        100
      ],
      [
        1588174929057,
        50.8,
        3,
        49.46,
        41.45,
        60.94,
        50
      ],
      [
        1588174928486,
        78.05,
        3,
        78.92,
        84.15,
        100,
        50
      ],
      [
        1588174918472,
        85.06,
        3,
        83.2,
        92.37,
        62.82,
        100
      ],
      [
        1588174917529,
        91.74,
        3,
        90.7,
        91.88,
        83.33,
        100
      ],
      [
        1588174917709,
        97.46,
        3,
        97.14,
        92.37,
        100,
        100
      ],
      [
        1588174917731,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174917580,
        66.18,
        3,
        74.46,
        98.55,
        100,
        0
      ],
      [
        1588174917176,
        99.15,
        3,
        99.36,
        100,
        100,
        97.44
      ],
      [
        1588174918073,
        51.25,
        3,
        55.17,
        78.74,
        50,
        25
      ],
      [
        1588174917832,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174917367,
        78.41,
        3,
        81.96,
        85.22,
        100,
        50
      ],
      [
        1588174917493,
        99.52,
        3,
        99.46,
        98.55,
        100,
        100
      ],
      [
        1588174942546,
        51.59,
        3,
        52.05,
        54.78,
        50,
        50
      ],
      [
        1588174942437,
        99.36,
        3,
        99.17,
        98.07,
        100,
        100
      ],
      [
        1588174942437,
        99.36,
        3,
        99.17,
        98.07,
        100,
        100
      ],
      [
        1588174936279,
        95.97,
        3,
        95.47,
        99.03,
        88.89,
        100
      ],
      [
        1588174978600,
        57.59,
        3,
        60.16,
        75.56,
        72.22,
        25
      ],
      [
        1588174914573,
        41.85,
        3,
        41.26,
        37.73,
        50,
        37.82
      ],
      [
        1588174914486,
        35.47,
        3,
        34.89,
        31.42,
        50,
        25
      ],
      [
        1588174913607,
        68.25,
        3,
        66.32,
        54.75,
        100,
        50
      ],
      [
        1588174914115,
        52.68,
        3,
        57.02,
        83.05,
        50,
        25
      ],
      [
        1588174914397,
        49.24,
        3,
        49.03,
        47.73,
        50,
        50
      ],
      [
        1588174914263,
        80.87,
        3,
        82.55,
        92.62,
        50,
        100
      ],
      [
        1588174913957,
        95.2,
        3,
        93.83,
        85.6,
        100,
        100
      ],
      [
        1588174913971,
        37.58,
        3,
        37.6,
        37.73,
        50,
        25
      ],
      [
        1588174914913,
        46.03,
        3,
        44.9,
        38.09,
        50,
        50
      ],
      [
        1588174913745,
        42.09,
        3,
        41.57,
        38.44,
        50,
        37.82
      ],
      [
        1588174914714,
        64.5,
        3,
        65.08,
        68.51,
        100,
        25
      ],
      [
        1588174978755,
        36.03,
        3,
        38.1,
        50.53,
        57.55,
        0
      ],
      [
        1588174933828,
        66.34,
        3,
        71.01,
        99.03,
        75,
        25
      ],
      [
        1588174934062,
        89.11,
        3,
        87.91,
        68.6,
        100,
        98.72
      ],
      [
        1588174931761,
        58.01,
        3,
        63.87,
        99.03,
        50,
        25
      ],
      [
        1588174934565,
        57.36,
        3,
        61.2,
        84.25,
        50,
        37.82
      ],
      [
        1588174931731,
        63.64,
        3,
        67.54,
        90.92,
        50,
        50
      ],
      [
        1588174933396,
        66.02,
        3,
        74.28,
        98.07,
        100,
        0
      ],
      [
        1588174932121,
        99.84,
        3,
        99.82,
        99.52,
        100,
        100
      ],
      [
        1588174932232,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174933005,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174932366,
        82.53,
        3,
        84.68,
        97.58,
        100,
        50
      ],
      [
        1588174931590,
        90.81,
        3,
        93.11,
        100,
        100,
        72.44
      ],
      [
        1588174934223,
        49.36,
        3,
        56.32,
        98.07,
        50,
        0
      ],
      [
        1588174933108,
        37.31,
        3,
        40.83,
        61.93,
        50,
        0
      ],
      [
        1588174932639,
        56.89,
        3,
        64,
        80.92,
        89.74,
        0
      ],
      [
        1588174933891,
        45.07,
        3,
        50.81,
        85.22,
        50,
        0
      ],
      [
        1588174933529,
        99.84,
        3,
        99.82,
        99.52,
        100,
        100
      ],
      [
        1588174933157,
        66.18,
        3,
        74.46,
        98.55,
        100,
        0
      ],
      [
        1588174932777,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174934238,
        95.13,
        3,
        95.69,
        99.03,
        86.37,
        100
      ],
      [
        1588174933705,
        99.68,
        3,
        99.58,
        99.03,
        100,
        100
      ],
      [
        1588174932473,
        74.36,
        3,
        77.74,
        98.07,
        100,
        25
      ],
      [
        1588174931889,
        80.47,
        3,
        84.28,
        91.4,
        100,
        50
      ],
      [
        1588174933321,
        74.36,
        3,
        80.53,
        98.07,
        100,
        25
      ],
      [
        1588174932797,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174931970,
        71.42,
        3,
        74.09,
        97.58,
        66.67,
        50
      ],
      [
        1588174934383,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174934381,
        53.46,
        3,
        52.76,
        48.6,
        61.77,
        50
      ],
      [
        1588174934912,
        34.61,
        3,
        37.35,
        53.82,
        50,
        0
      ],
      [
        1588174935397,
        32.38,
        3,
        34.49,
        47.15,
        50,
        0
      ],
      [
        1588174935193,
        32.38,
        3,
        34.49,
        47.15,
        50,
        0
      ],
      [
        1588174935034,
        55.16,
        3,
        53.06,
        40.48,
        100,
        25
      ],
      [
        1588174934855,
        63.16,
        3,
        65.01,
        76.14,
        63.34,
        50
      ],
      [
        1588174949078,
        81.74,
        3,
        79.45,
        92.27,
        52.94,
        100
      ],
      [
        1588174948346,
        42.2,
        3,
        41.72,
        38.79,
        50,
        37.82
      ],
      [
        1588174949594,
        59.67,
        3,
        63.86,
        89.01,
        90,
        0
      ],
      [
        1588174948461,
        59.7,
        3,
        60.92,
        54.4,
        74.71,
        50
      ],
      [
        1588174949280,
        71.52,
        3,
        75.73,
        76.74,
        100,
        37.82
      ],
      [
        1588174948196,
        99.76,
        3,
        99.73,
        99.29,
        100,
        100
      ],
      [
        1588174947861,
        99.88,
        3,
        99.87,
        99.65,
        100,
        100
      ],
      [
        1588174948730,
        70.35,
        3,
        69.03,
        61.06,
        100,
        50
      ],
      [
        1588174948244,
        99.15,
        3,
        99.36,
        100,
        100,
        97.44
      ],
      [
        1588174948639,
        29.36,
        3,
        30.61,
        38.09,
        50,
        0
      ],
      [
        1588174948834,
        46.03,
        3,
        44.9,
        38.09,
        50,
        50
      ],
      [
        1588174949749,
        76.67,
        3,
        78.89,
        92.2,
        100,
        37.82
      ],
      [
        1588174949489,
        95.32,
        3,
        94.73,
        85.96,
        100,
        100
      ],
      [
        1588174949415,
        71.52,
        3,
        75.73,
        76.74,
        100,
        37.82
      ],
      [
        1588174948007,
        80.64,
        3,
        82.25,
        91.91,
        50,
        100
      ],
      [
        1588174949927,
        54.24,
        3,
        51.88,
        37.73,
        100,
        25
      ],
      [
        1588174948939,
        66.48,
        3,
        71.66,
        75.67,
        98.77,
        25
      ],
      [
        1588174949495,
        73.86,
        3,
        78.36,
        83.76,
        100,
        37.82
      ],
      [
        1588174950085,
        48.25,
        3,
        47.75,
        44.75,
        50,
        50
      ],
      [
        1588174947666,
        91.07,
        3,
        89.96,
        92.27,
        80.95,
        100
      ],
      [
        1588174947757,
        45.12,
        3,
        46.45,
        54.4,
        55.97,
        25
      ],
      [
        1588174924173,
        96.01,
        3,
        95.42,
        91.88,
        100,
        96.16
      ],
      [
        1588174923859,
        72.81,
        3,
        75.66,
        98.07,
        70.37,
        50
      ],
      [
        1588174921616,
        97.62,
        3,
        97.32,
        92.85,
        100,
        100
      ],
      [
        1588174925838,
        60.73,
        3,
        60.23,
        57.2,
        100,
        25
      ],
      [
        1588174924652,
        65.89,
        3,
        67.88,
        47.68,
        100,
        50
      ],
      [
        1588174798332,
        31.98,
        3,
        28.57,
        8.12,
        25,
        62.82
      ],
      [
        1588174921196,
        50.63,
        3,
        48.98,
        39.08,
        50,
        62.82
      ],
      [
        1588174925193,
        40.47,
        3,
        44.89,
        71.4,
        50,
        0
      ],
      [
        1588174922328,
        57.2,
        3,
        61.2,
        85.22,
        86.37,
        0
      ],
      [
        1588174920884,
        46.04,
        3,
        44.91,
        38.12,
        50,
        50
      ],
      [
        1588174925015,
        83.41,
        3,
        84.62,
        91.88,
        100,
        58.34
      ],
      [
        1588174921731,
        51.17,
        3,
        57.56,
        57.2,
        96.3,
        0
      ],
      [
        1588174922673,
        63.67,
        3,
        65.38,
        57.2,
        83.81,
        50
      ],
      [
        1588174922255,
        76.41,
        3,
        81.23,
        91.4,
        100,
        37.82
      ],
      [
        1588174925401,
        54.78,
        3,
        56.15,
        64.35,
        100,
        0
      ],
      [
        1588174924295,
        76.57,
        3,
        81.41,
        91.88,
        100,
        37.82
      ],
      [
        1588174921307,
        91.72,
        3,
        91.32,
        91.88,
        88.4,
        94.87
      ],
      [
        1588174923017,
        99.68,
        3,
        99.64,
        99.03,
        100,
        100
      ],
      [
        1588174922070,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174923773,
        69.23,
        3,
        67.58,
        57.68,
        100,
        50
      ],
      [
        1588174922388,
        64.95,
        3,
        66.82,
        57.68,
        87.18,
        50
      ],
      [
        1588174926495,
        63.96,
        3,
        71.95,
        91.88,
        100,
        0
      ],
      [
        1588174925451,
        50.63,
        3,
        49.19,
        40.53,
        86.37,
        25
      ],
      [
        1588174920755,
        93.16,
        3,
        92.79,
        100,
        83.33,
        96.16
      ],
      [
        1588174924785,
        43.14,
        3,
        48.53,
        47.2,
        82.22,
        0
      ],
      [
        1588174923469,
        75.72,
        3,
        74.1,
        64.35,
        100,
        62.82
      ],
      [
        1588174924451,
        56.81,
        3,
        58.76,
        70.43,
        100,
        0
      ],
      [
        1588174924688,
        59.46,
        3,
        58.59,
        53.38,
        100,
        25
      ],
      [
        1588174921475,
        95.23,
        3,
        94.64,
        85.7,
        100,
        100
      ],
      [
        1588174922499,
        76.57,
        3,
        81.41,
        91.88,
        100,
        37.82
      ],
      [
        1588174921642,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174921908,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174923378,
        95.65,
        3,
        95.11,
        98.07,
        88.89,
        100
      ],
      [
        1588174926392,
        92.91,
        3,
        92.84,
        92.37,
        86.37,
        100
      ],
      [
        1588174923789,
        68.12,
        3,
        70.38,
        54.35,
        100,
        50
      ],
      [
        1588174924018,
        99.52,
        3,
        99.38,
        98.55,
        100,
        100
      ],
      [
        1588174922867,
        69.24,
        3,
        71.64,
        70.53,
        87.18,
        50
      ],
      [
        1588174921739,
        66.72,
        3,
        68.81,
        53.86,
        96.3,
        50
      ],
      [
        1588174924636,
        52.15,
        3,
        49.19,
        31.45,
        100,
        25
      ],
      [
        1588174921218,
        77.6,
        3,
        81.05,
        84.25,
        98.55,
        50
      ],
      [
        1588174923005,
        78.63,
        3,
        83.73,
        98.07,
        100,
        37.82
      ],
      [
        1588174923377,
        93.84,
        3,
        93.07,
        85.7,
        95.83,
        100
      ],
      [
        1588174925531,
        53.98,
        3,
        55.11,
        61.93,
        50,
        50
      ],
      [
        1588174926184,
        48.42,
        3,
        47.97,
        45.27,
        50,
        50
      ],
      [
        1588174923587,
        56.79,
        3,
        57.64,
        41.01,
        79.37,
        50
      ],
      [
        1588174925736,
        64.42,
        3,
        69.34,
        90.92,
        77.33,
        25
      ],
      [
        1588174926450,
        99.68,
        3,
        99.64,
        99.03,
        100,
        100
      ],
      [
        1588174931022,
        41.91,
        2,
        40.29,
        33.82,
        null,
        50
      ],
      [
        1588174926403,
        55.34,
        3,
        55.4,
        55.75,
        60.26,
        50
      ],
      [
        1588174925049,
        86.21,
        3,
        84.47,
        74.01,
        100,
        84.62
      ],
      [
        1588174926006,
        72.46,
        3,
        75.3,
        92.37,
        100,
        25
      ],
      [
        1588174977473,
        70.71,
        3,
        67.21,
        46.74,
        66.67,
        98.72
      ],
      [
        1588174977333,
        40.26,
        3,
        44.62,
        70.78,
        25,
        25
      ],
      [
        1588174956694,
        87.2,
        3,
        87.66,
        90.43,
        100,
        71.16
      ],
      [
        1588174956485,
        56.81,
        3,
        58.76,
        70.43,
        100,
        0
      ],
      [
        1588174956142,
        65.86,
        3,
        70.39,
        97.58,
        100,
        0
      ],
      [
        1588174956078,
        78.64,
        3,
        78.49,
        77.58,
        100,
        58.34
      ],
      [
        1588174955905,
        89.9,
        3,
        91.14,
        98.55,
        100,
        71.16
      ],
      [
        1588174956263,
        71.81,
        3,
        74.47,
        90.43,
        100,
        25
      ],
      [
        1588174956392,
        80.7,
        3,
        81.14,
        83.77,
        100,
        58.34
      ],
      [
        1588174950376,
        32.9,
        3,
        31.58,
        23.69,
        50,
        25
      ],
      [
        1588174951790,
        68.54,
        3,
        70.66,
        83.4,
        72.22,
        50
      ],
      [
        1588174951047,
        41.97,
        3,
        41.42,
        38.09,
        50,
        37.82
      ],
      [
        1588174950801,
        52.28,
        3,
        50.25,
        38.09,
        93.75,
        25
      ],
      [
        1588174950420,
        99.11,
        3,
        99.16,
        98.94,
        99.66,
        98.72
      ],
      [
        1588174950555,
        85.04,
        3,
        83.17,
        55.11,
        100,
        100
      ],
      [
        1588174951212,
        70.47,
        3,
        69.18,
        61.42,
        100,
        50
      ],
      [
        1588174950555,
        51.7,
        3,
        58.17,
        55.11,
        100,
        0
      ],
      [
        1588174950555,
        67.73,
        3,
        70.19,
        55.11,
        100,
        48.08
      ],
      [
        1588174950175,
        67.58,
        3,
        69.03,
        77.73,
        100,
        25
      ],
      [
        1588174951489,
        38.09,
        3,
        41.83,
        64.26,
        50,
        0
      ],
      [
        1588174951957,
        35.47,
        3,
        34.89,
        31.42,
        50,
        25
      ],
      [
        1588174950555,
        43.37,
        3,
        45.05,
        55.11,
        50,
        25
      ],
      [
        1588174951891,
        100,
        3,
        100,
        100,
        100,
        100
      ],
      [
        1588174951392,
        80.76,
        3,
        82.4,
        92.27,
        50,
        100
      ],
      [
        1588174951176,
        82.7,
        3,
        77.75,
        48.09,
        100,
        100
      ],
      [
        1588174950555,
        60.04,
        3,
        64.42,
        55.11,
        100,
        25
      ],
      [
        1588174950978,
        37.81,
        3,
        37.9,
        38.44,
        50,
        25
      ],
      [
        1588174951734,
        37.58,
        3,
        37.6,
        37.73,
        50,
        25
      ],
      [
        1588174950574,
        94.16,
        3,
        93.43,
        92.27,
        90.2,
        100
      ],
      [
        1588174950716,
        45.27,
        3,
        46.62,
        54.75,
        56.06,
        25
      ]
    ],
    "matching": [
      "fetched",
      "score",
      "cardinal",
      "weightedScore",
      "analyticsScore",
      "tagmanagerScore",
      "campaignmanagerScore"
    ],
    "updated": 1588175228588
  }
}