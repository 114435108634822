import { createRouter, createWebHistory } from 'vue-router'
import { useMainStore } from '@/stores/main'
import type { RouteRecordRaw } from 'vue-router'

// Constants
export const TOOLS = ['google-analytics4', 'google-tag-manager', 'campaign-manager'] as const
export const SECTIONS = ['structure', 'data-privacy', 'uncategorized'] as const

// Path mappings
const PATH_TO_PAGE_MAP: Record<string, string> = {
  '/': 'list-tools',
  '/tools': 'list-tools',
  '/topics': 'list-topics',
  '/issues': 'issues'
}

// Route patterns for dynamic page names
const ROUTE_PATTERNS = [
  {
    pattern: /^\/(?:structure|data-privacy|uncategorized|google-analytics4(?:\/(?:structure|data-privacy|uncategorized))?|google-tag-manager(?:\/(?:structure|data-privacy|uncategorized))?|campaign-manager(?:\/(?:structure|uncategorized))?)$/,
    transform: (path: string) => 'list-' + path.slice(1)
      .replace(/google-/g, '')
      .replace(/-/g, '')
      .replace(/\//g, '-')
  },
  {
    pattern: /^\/.*\/action-plan$/,
    transform: (path: string) => 'actionplan-' + path.slice(1)
      .replace(/\/action-plan$/, '')
      .replace(/google-/g, '')
      .replace(/-/g, '')
      .replace(/\//g, '-')
  },
  {
    pattern: /^\/issues\/(?:google-analytics4?|google-tag-manager|campaign-manager)$/,
    transform: (path: string) => 'list-' + path.slice(1)
      .replace(/google-/g, '')
      .replace(/-/g, '')
      .replace(/\//g, '-')
  }
]

// Documentation route generator
const generateDocRoutes = () => {
  const routes: RouteRecordRaw[] = [
    {
      path: 'release-notes',
      name: 'release-notes',
      component: () => import('@/components/views/documentation/DocumentationView.vue')
    }
  ]

  TOOLS.forEach(tool => {
    const sections = tool === 'campaign-manager'
      ? ['structure', 'uncategorized']
      : SECTIONS

    sections.forEach(section => {
      routes.push({
        path: `${tool}/${section}`,
        name: `${tool}-${section}`,
        component: () => import('@/components/views/documentation/DocumentationView.vue')
      })
    })
  })

  return routes
}

// Main routes configuration
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/tools'
  },
  {
    path: '/tools',
    name: 'list-tools',
    component: () => import('@/components/views/ListView.vue')
  },
  {
    path: '/topics',
    name: 'list-topics',
    component: () => import('@/components/views/ListView.vue')
  },
  {
    path: '/:tool(google-analytics4|google-tag-manager|campaign-manager)?/:section(structure|data-privacy|uncategorized)?',
    name: 'list-view',
    component: () => import('@/components/views/ListView.vue')
  },
  {
    path: '/issues',
    name: 'issues',
    component: () => import('@/components/views/business-topics/IssuesTopicView.vue')
  },
  {
    path: '/issues/:tool(google-analytics4|google-tag-manager|campaign-manager)',
    name: 'list-issues',
    component: () => import('@/components/views/ListView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/components/views/documentation/DocumentationView.vue'),
    children: generateDocRoutes()
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/components/views/admin/AdminView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/components/views/NotFoundView.vue')
  }
]

// Router instance
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

// Navigation guard
router.beforeEach((to, from, next) => {
  const mainStore = useMainStore()
  let page: string

  if (PATH_TO_PAGE_MAP[to.path]) {
    page = PATH_TO_PAGE_MAP[to.path]
  } else {
    const matchedPattern = ROUTE_PATTERNS.find(p => p.pattern.test(to.path))
    if (matchedPattern) {
      page = matchedPattern.transform(to.path)
    } else if (to.path.startsWith('/about')) {
      page = 'about'
    } else if (to.path.startsWith('/admin')) {
      page = 'admin'
    } else {
      page = 'not-found'
    }
  }

  mainStore.updatePage(page)
  next()
})

export default router