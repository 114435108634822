import type { GroupParams } from "@/types/shared.types";
import { roundScore } from "@/utils";

interface ScoreObject {
  actual: string | null;
  expected: string | null;
}

type ScoreValue = number | string | ScoreObject | null | undefined;
type ScoreClass = 'null' | 'bad' | 'warn' | 'good';

export const getScoreClass = (score: ScoreValue): ScoreClass => {
  if (score === undefined || score === null || score === "n/a") {
    return "null";
  }

  if (typeof score === "string" && score.match(/^[0-9]+ \/ [0-9]+$/)) {
    const [a, b] = score.split(" / ");
    score = a === "0" && b === "0"
      ? 100
      : roundScore((Number(a) * 100) / Number(b));
  }

  if (typeof score === "object" && score !== null && "actual" in score && "expected" in score) {
    if (score.actual === "n/a" || score.expected === "n/a") {
      return "null";
    }
    if (score.actual === null) {
      return "bad";
    }
    return score.actual === score.expected ? "good" : "warn";
  }

  return score === 100 ? "good" : Number(score) >= 50 ? "warn" : "bad";
};

export const isThisGroupActivated = (topic: string, params: GroupParams): boolean => {
  return params.activatedFeatures?.includes(`${params.tool}.${topic}`) ?? false;
};

export const toScore = (arg: number | string | null): string => {
  if (arg === null || arg === "n/a") {
    return "n/a";
  }
  return typeof arg === "number" ? `${roundScore(arg)}%` : '…';
};

export const toString = (arg: any): string => {
  return arg !== undefined && arg !== '' && arg !== null ? String(arg) : '…';
};

export const toStringNotApplicable = (arg: any): string => {
  return arg !== undefined && arg !== '' && arg !== null ? String(arg) : 'n/a';
};

export const toBoolean = (arg: string | number | boolean): boolean => {
  return arg === true || arg === 'true' || arg === 1;
};

export const smartDateTime = (timestamp: number | null): string => {
  if (!timestamp) {
    return 'never';
  }

  const date = new Date(timestamp);
  const dateString = date.toDateString();
  const currentDate = new Date();

  return currentDate.toDateString() === dateString
    ? date.toLocaleTimeString()
    : dateString;
};